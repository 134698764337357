import React from "react";

export class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open(analytics=false) {
    if (analytics && typeof _satellite == "object") _satellite.track('modal-opened', { name: this.props.title });
    this.setState({ open: true }, this.props.onOpen);
  }

  close(analytics=false) {
    if (analytics && typeof _satellite == "object") _satellite.track('modal-closed', { name: this.props.title });
    this.setState({ open: false }, this.props.onClose);
  }

  render() {
    return (
      <span>
        <a className="modal-trigger" onClick={this.open} data-track="open-modal" data-track-modal-name={this.props.title} >{this.props.trigger}</a>
        <div className={`modal-container${ this.state.open ? ' open' : '' }`} >
          <div className="modal-overlay" onClick={this.close} data-track="close-modal" data-track-modal-name={this.props.title} ></div>
          <div className={`modal-frame ${ this.props.className }`} >
            <div className="modal-close" onClick={this.close} data-track="close-modal" data-track-modal-name={this.props.title} ><i className="fa fa-times" aria-hidden="true"></i></div>
            <h5 className="modal-title" >{this.props.title}</h5>
            <div className="modal-body" >{this.props.children}</div>
            <div className="modal-buttons" >
              {
                this.props.buttons.map((button, index) => {
                  return <div key={index} className="modal-button-container" >{button}</div>;
                })
              }
            </div>
          </div>
        </div>
      </span>
    );
  }
};


export class ModalButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="oc-cta-button modal-button" onClick={this.props.onPush} >{this.props.label}</div>
    );
  }
}
