import styled from '@emotion/styled';

// Components
import { H3 } from '../../ComponentLibrary/text-elements/oc-h';
import HeroTier3 from '../../ComponentLibrary/heros/oc-hero-tier3';
import Section from '../../ComponentLibrary/layout/oc-section';
import Grid from '../../ComponentLibrary/oc-grid';
import NoGapGrid from '../../ComponentLibrary/oc-no-gap-grid';

// Utilities
import { presentMoneyRange } from "./functions/sharedFunctions";

// Icons
import IconInfo from "../../ComponentLibrary/icons/icon-info";
import IconCheck from '../../ComponentLibrary/icons/icon-check';
import IconExlamationTriangle from '../../ComponentLibrary/icons/icon-exclamation-triangle';

// Styled Components
const ValidatedSmartyAddress = styled.p`
  margin: 8px 0px 0px 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  background: #F8F8F8;
  max-width: 190px;
  height: 34px;
  padding: 8px;
  display: inline;
  vertical-align: middle;
  `;

const UnvalidatedStreetIcon = styled(IconExlamationTriangle)`
  color: #D65900;
  width: 16px;
  height: 14px;
`
const ValidStreetIcon = styled(IconCheck)`
  color: #3C9748;
  margin-right: 0.5rem;
  width: 16px;
  height: 12px;
`

const Intro = styled.p`
  margin: 32px 0;
`;

const Notice = styled.p`
  background-color: #F8F8F8;
  display: inline-block;
  padding: 10px 8px;
`;

const Details = styled.div`
  display: inline-block;
  margin: 16px 0;
  position: relative;
`;

const P = styled.p`
  margin: 0 0 8px;
`;

const P2 = styled.p`
  margin: 0 0 8px;
	text-decoration: underline;
`;

const P3 = styled.p`
  margin: 0 0 8px;
	line-height:150%;
`;

const TotalEstimation = styled.div`
  background-color: #F8F8F8;
  border-bottom: 2px solid black;
  border-top: 2px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 24px;

  h3, h4 {
    color: #D40F7D !important;
    margin-bottom: 0 !important;
  }

  p {
    margin: 0;
    font-size: 12px;
  }

  &.contingencies-estimation {
    margin-top: 64px;
  }

  > * {
    align-self: center;
  }
`;


const Icon = styled(IconInfo)`
  margin-right: 5px;
`;

const HomeownerInfo = ({contactInfo, roofDetails, configuration, currentUserToken, materials}) => {
  const minEstimate = configuration.existingQuote.minEstimate;
  const maxEstimate = configuration.existingQuote.maxEstimate;
  return (
    <>
      <HeroTier3
        prehead="Owens Corning®"
        h1="Budget Your Roof"
        imgDesktop={"https://imagecdn.owenscorning.com/ocimages/image/upload/q_auto,f_auto/v1671737173/roofing/quoting-widget/qw-configurator-hero.jpg"}
        imgTablet={"https://imagecdn.owenscorning.com/ocimages/image/upload/c_crop,h_245,w_800,x_320,y_0/v1671737173/roofing/quoting-widget/qw-configurator-hero.jpg"}
      />

      <Section noMarginTop>
        <Intro>
          A homeowner completed the Budget Your Roof widget on your website. We've gathered together the information submitted.
        </Intro>
        <TotalEstimation>
          <div>
            <H3>Approximate Roofing Budget</H3>
          </div>
          <H3>{presentMoneyRange(minEstimate, maxEstimate, false)} </H3>
        </TotalEstimation>
      </Section>

      <Section>
        <Grid selectedSize='2' selectedSizeTablet='2'>
          <NoGapGrid selectedSize='1' selectedSizeTablet='1' colWidth='100%'>
            <H3>Homeowner Information</H3>
            { contactInfo ?
              contactInfo.map((contact, index) => (
                <Details key={contact.label}>
                  <P><strong>{contact.label}</strong></P>
                  { contact.label === 'Email' ||( contact.label === 'Secondary Email' && contact.value) || ( contact.label === 'Phone Number' && contact.value !== 'Not provided by lead' ) ?
                    <P2 dangerouslySetInnerHTML={{__html: contact.value}}/>
                    :
                    <P dangerouslySetInnerHTML={{__html: contact.value}} />
                  }
                  { contact.hasDuplicateDifference &&
                    <Notice>
                      <Icon/>
                      Information varies between duplicate submissions
                    </Notice>
                  }
                  { contact.label === 'Address' &&
                    <>
                    { contact.validatedAddress === true ?
                      <ValidatedSmartyAddress> <ValidStreetIcon/>Validated Address</ValidatedSmartyAddress>
                      :
                      <ValidatedSmartyAddress> <UnvalidatedStreetIcon/> Unvalidated Address</ValidatedSmartyAddress>
                    }
                    </>
                  }
                </Details>
              ))
              :
                <p>No details available</p>
            }
            {roofDetails ?
              roofDetails.map((detail) => (
                !detail.hidden &&
                  <Details key={detail.label}>
                    <P><strong>{detail.label}</strong><br/></P>
                    { Array.isArray(detail.value) ?
                        <P3 dangerouslySetInnerHTML={{__html: detail.value.join('<br>')}} />
                      :
                        <P dangerouslySetInnerHTML={{__html: detail.value}} />
                    }
                    { detail.hasDuplicateDifference &&
                      <Notice>
                        <Icon/>
                        Information varies between duplicate submissions
                      </Notice>
                    }
                  </Details>
              ))
              :
                <p>No details available</p>
            }
          </NoGapGrid>
          <NoGapGrid selectedSize='1' selectedSizeTablet='1' colWidth='100%' autoRows='max-content'>
            <H3>Materials</H3>
            {materials ?
              materials.map((detail) => (
                !detail.hidden &&
                  <Details key={detail.label}>
                    <P><strong>{detail.label}</strong><br/></P>
                    { Array.isArray(detail.value) ?
                        <P3 dangerouslySetInnerHTML={{__html: detail.value.join('<br>')}} />
                      :
                        <P dangerouslySetInnerHTML={{__html: detail.value}} />
                    }
                    { detail.hasDuplicateDifference &&
                      <Notice>
                        <Icon/>
                        Information varies between duplicate submissions
                      </Notice>
                    }
                  </Details>
              ))
              :
                <p>No details available</p>
            }
          </NoGapGrid>
        </Grid>
      </Section>
    </>
	);
}

export default HomeownerInfo;
