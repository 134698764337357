// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("turbolinks").start()

// require('@rails/activestorage').start();
// require('channels');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Initially require by brand_center

require('@rails/ujs').start();
require('@rails/activestorage').start();

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'event-target-polyfill';

import 'polyfills';

import ReactOnRails from 'react-on-rails';

import AlertMessage from "../components/OC/oc-alert-message";
import FlexBanner from "../components/OC/oc-flex-banner";
import AttentionBarWithTimer from "../components/OC/oc-attention-bar-with-timer";
import HVACSplash from "../components/HVACSplash";
import IFramedForm from "../components/IFramedForm";
import VideoWrapper from "../components/OC/oc-video-wrapper";
import Switcher from "../components/location/Switcher";
import ToggleFooter from "../components/ToggleFooter";
import QuotingWidgetConfigurator from '../components/Roofing/QuotingWidget/Configurator';
import HomeownerInfo from '../components/Roofing/QuotingWidget/HomeownerInfo';
import PinkBarVsSteelCalculator from "../components/composites/PinkBarVsSteel/PinkBarVsSteelCalculator";

import AttentionBar from "../components/OC/PageBuilder/AttentionBarPicker";
import Hero from "../components/OC/oc-hero";
import FeatureFlagAlert from "../components/FeatureFlag/FeatureFlagAlert";
import Reusable from "../components/OC/PageBuilder/Reusable";

import TipsAndTricks from "../components/brand_center/Modals/TipsAndTricks";
import SearchResultsIndex from "../components/brand_center/Search/Results/Index";
import AssetDownloadsIndex from "../components/brand_center/Asset/Downloads/Index";
import AssetDownloadsThumbnail from "../components/brand_center/Asset/Downloads/Thumbnail";
import Footer from "../components/OC/footer";
import FindAProfessional from '../components/Insulation/FindAProfessional';

// This is how react_on_rails can see the react_component('ComponentName') in the browser.
// Use :: in place of / and capitalize folder/component names
ReactOnRails.register({
  'OC::Hero': Hero,
  'FeatureFlag::Featureflagalert': FeatureFlagAlert,
  'OC::Oc-alert-message': AlertMessage,
  'OC::Oc-flex-banner': FlexBanner,
  'OC::Oc-attention-bar-with-timer': AttentionBarWithTimer,
  'HVACSplash': HVACSplash,
  'IFramedForm': IFramedForm,
  'OC::Oc-video-wrapper': VideoWrapper,
  'Location::Switcher': Switcher,
  'Roofing::Quotingwidget::Customize': QuotingWidgetConfigurator,
  'Roofing::Quotingwidget::Homeownerinfo': HomeownerInfo,
  'Composites::Pinkbarvssteel::Pinkbarvssteelcalculator': PinkBarVsSteelCalculator,
  ToggleFooter,
  'OC::Footer': Footer,

  'AttentionBar': AttentionBar,
  'Builder::Reusable': Reusable,

  'BrandCenter::Modals::Tipsandtricks': TipsAndTricks,
  'BrandCenter::Search::Results::Index': SearchResultsIndex,
  'BrandCenter::Asset::Downloads::Index': AssetDownloadsIndex,
  'BrandCenter::Asset::Downloads::Thumbnail': AssetDownloadsThumbnail,
  'Insulation::FindAProfessional::Index': FindAProfessional,
});
