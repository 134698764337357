import styled from "@emotion/styled";

const Container = styled.div`
  background-color: #000000;
  color: #FFFFFF;
  display: inline-block;
  font-size: 15px;
  line-height: 1.2;
  padding: 12px 16px;
  position: relative;
  width: 100%;
`;

const Banner = ({ children, className }) => {
  return (
    <Container className={className}>
      {children}
    </Container>
  );
}

export default Banner;
