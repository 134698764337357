import { useEffect } from "react";
import styled from "@emotion/styled";
import _ from "lodash";

// components
import FormButton from '../../../ComponentLibrary/input-elements/oc-form-button';


// styled components
const FormSection = styled.div`
  display: inline-block;
  margin: 24px 0;
  &.split {
    display: flex;
    flex-wrap: wrap;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
  position: relative;

  button {
    margin-right: 1em;
  }
`;

const QuoteDetailsForm = ({ item ,closeModal, deleteItem, index }) => {

  return (
    <>
      <h3 uppercase="true" mt="14">Delete Item</h3>
      <form onSubmit={() => deleteItem(index)}>
        <FormSection>
          <p>{`Are you sure you want to delete ${item.title} ? You can't undo this action. `}</p>
        </FormSection>
        <ModalFooter>
          <FormButton type="submit">
            YES, DELETE ITEM
          </FormButton>
          <FormButton
            onClick={() => closeModal()}
            background="white"
            color="#FFFFFF"
          >
          Cancel
          </FormButton>
        </ModalFooter>
      </form>
    </>
  )

};
export default QuoteDetailsForm;
