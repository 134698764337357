import { useState } from "react";
import styled from "@emotion/styled";
import _ from "lodash";

// Components
import Hero from "../../OC/oc-hero";
import CreateOrEdit from "./Configurator/CreateOrEdit";
import EditForm from "./Configurator/EditForm";
import FormModal from "../../ComponentLibrary/forms/oc-form-modal";

// Styled Components
const Section = styled.section`
  border-bottom: 2px solid #000000;
  margin-bottom: 64px;
  padding-bottom: 32px;

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const SectionCopy = styled.p`
  margin-bottom: 32px;
`;

const QuotingWidgetConfigurator = ({
  locale,
  currentUserToken,
  shingles,
  componentProducts,
  configurations,
  selectedConfiguration,
  newConfiguration
}) => {
  const [configurationsList, setConfigurationsList] = useState(configurations);
  const [activeConfiguration, setActiveConfiguration] = useState(
    _.size(selectedConfiguration) ? _.cloneDeep(selectedConfiguration) : _.cloneDeep(newConfiguration)
  );
  const [displayEditTable, setDisplayEditTable] = useState(false);
  const [isEditing, setIsEditing] = useState(!!selectedConfiguration.id);
  const [saving, setSaving] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalSize, setModalSize] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [showPricingWarning, setShowPricingWarning] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const updateData = (event, field, value = null) => {
    let config = _.cloneDeep(activeConfiguration);
    let newValue = value ?? event.target.value;
    config.data[field] = newValue;
    setActiveConfiguration(config);
  }

  const saveConfiguration = () => {
    if (showPricingWarning) {
      setShowWarningModal(true);
    }
    else {
      let bodyObject = _.omit(activeConfiguration, "data");
      let data = activeConfiguration.data;
      _.isArray(data['leadsEmail']) ? data['leadsEmail'] : data['leadsEmail'].split(',');
      data["standOuts"] = _.reject(data["standOuts"], (object) => { return !object.title && !object.content });
      bodyObject["app_data"] = data;

      setSaving(true);
      fetch(`${MDMS_URL}/api/v1/app_data/qw_configuration`, {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${currentUserToken}` },
        body: JSON.stringify(bodyObject),
      }).then(
        response => response.json()
      ).then(json => {
        let savedConfiguration = json;
        let configurationId = savedConfiguration.id;
        let updatedConfigurationsList = configurationsList.filter(config => config.id !== configurationId)

        setConfigurationsList([savedConfiguration, ...updatedConfigurationsList]);
        setActiveConfiguration(savedConfiguration);
        setIsEditing(true);
        setSaving(false);
      })
    }
  }

  const closeWarningModal = () => {
    setShowWarningModal(false);
  }

  const openWarningModal = () => {
    setShowWarningModal(true);
  }

  const openModal = (content, size) => {
    setModalContent(content);
    setModalSize(size);
    setDisplayModal(true);
  }

  const closeModal = () => {
    setDisplayModal(false);
  }

  const handleWarningChange = (newWarningState) => {
    setShowPricingWarning(newWarningState);
  };


  return (
    <>
      <Hero
        prehead="Owens Corning&reg;"
        h1="Budget Your Roof <span class='pink'>Configurator</span>"
        imgDesktop={ { file:"https://imagecdn.owenscorning.com/ocimages/image/upload/v1671737173/roofing/quoting-widget/qw-configurator-hero.jpg" } }
        imgTablet={ { file: "https://imagecdn.owenscorning.com/ocimages/image/upload/v1671737173/roofing/quoting-widget/qw-configurator-hero.jpg" } }
      />

      {!isEditing &&
        <Section>
          <SectionCopy>
            Create your own version of the Owens Corning Budget Your Roof Widget to use as a lead generation tool on your website. This makes it easier for homeowners to get an idea on what they should budget to replace their roof and capture their contact information to schedule an appointment for your inspection and official estimate. Follow the prompts below to create your custom version today!
          </SectionCopy>

          <CreateOrEdit
            configurations={configurationsList}
            activeConfiguration={activeConfiguration}
            setActiveConfiguration={setActiveConfiguration}
            newConfiguration={newConfiguration}
            displayEditTable={displayEditTable}
            setDisplayEditTable={setDisplayEditTable}
            setIsEditing={setIsEditing}
            updateData={updateData}
            saveConfiguration={saveConfiguration}
            saving={saving}
          />
        </Section>
      }

      {isEditing &&
        <EditForm
          activeConfiguration={activeConfiguration}
          setActiveConfiguration={setActiveConfiguration}
          setIsEditing={setIsEditing}
          updateData={updateData}
          saveConfiguration={saveConfiguration}
          saving={saving}
          openModal={openModal}
          closeModal={closeModal}
          shingles={shingles}
          componentProducts={componentProducts}
          configurations={configurationsList}
          handleWarningChange={handleWarningChange}
          showPricingWarning={showPricingWarning}
          showWarningModal={showWarningModal}
          closeWarningModal={closeWarningModal}
          openWarningModal={openWarningModal}
        />
      }

      {displayModal &&
        <FormModal
          name="Quoting Widget Configuration Modal"
          onClose={() => closeModal()}
          open={true}
          size={modalSize}
        >
          {modalContent}
        </FormModal>
      }
    </>
  );
}

export default QuotingWidgetConfigurator;
