import { useState } from "react";
import styled from "@emotion/styled";
import _ from "lodash";
import { maxPriceValidator } from "../functions/sharedFunctions";

// Components
import TextInput from "../../../ComponentLibrary/input-elements/oc-text-input";
import FormButton from "../../../ComponentLibrary/input-elements/oc-form-button";
import Label from "../../../ComponentLibrary/input-elements/oc-label";
import NumberFormat from "react-number-format";

// Styled Components
const FormSection = styled.div`
  margin-top: 24px;
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
  position: relative;

  button {
    margin-right: 1em;
  }
`;

const Input = styled(NumberFormat, { displayType: "input" })`
  appearance: none;
  border-radius: 0;
  border: 1px solid #585858;
  display: inline-block;
  font-size: 16px;
  padding: 14px 8px;
  position: relative;
  width: 100%;
`;

const ContingencyForm = ({ contingency, isNew, closeModal, saveContingency }) => {
  const [modalContingency, setModalContingency] = useState(contingency);

  const modalChangeContingency = (event, field, value = null) => {
    let changedContingency = _.cloneDeep(modalContingency);
    let newValue = value ?? event.target.value;
    changedContingency[field] = newValue;

    setModalContingency(changedContingency);
  };

  const updatePricing = (event, field) => {
    const value = (event.floatValue) ? event.floatValue : 0;
    modalChangeContingency(null, field, value)
  };

  const modalTitle = () => {
    if (isNew)
      return "add new contingency";

    return "edit existing contingency";
  }

  return(
    <>
      <h3 uppercase="true" mt="14">{modalTitle()}</h3>
      <p>
        Continencies will display with your quote and will help homeowners understand factors that may
        influence the final price of their project. Please edit the placeholder content below to be
        accurate for your business.
      </p>
      <form onSubmit={(event) => saveContingency(event, modalContingency)}>
        <FormSection>
          <TextInput
            label="Name of Contingency"
            name="contingency-name"
            value={modalContingency.name}
            placeholder="Name of Contingency"
            onChange={(event) => modalChangeContingency(event, "name")}
            required={true}
          />
        </FormSection>
        <FormSection>
          <TextInput
            label="Description"
            name="contingency-description"
            multiline={true}
            onChange={(event) => modalChangeContingency(event, "description")}
            placeholder="Write a description of the contingency"
            rows={2}
            value={modalContingency.description}
            required={true}
          />
        </FormSection>
        <FormSection>
          <Label text="Cost of Contingency" helperText="Required" required={true} />
          <Input
            allowEmptyFormatting={true}
            allowNegative={false}
            decimalScale={2}
            isAllowed={(values) => maxPriceValidator(values, 100000)}
            onValueChange={(event) => updatePricing(event, "cost")}
            prefix="$"
            thousandSeparator={true}
            value={modalContingency.cost || "" }
          />
        </FormSection>

        <ModalFooter>
          <FormButton type="submit">
            {`${isNew ? "Add" : "Save"} Contingency`}
          </FormButton>
          <FormButton
            onClick={() => closeModal()}
            background="white"
            color="#FFFFFF"
          >
            Cancel
          </FormButton>
        </ModalFooter>
      </form>
    </>
  );
}

export default ContingencyForm;
