import React from "react";

import { Modal, ModalButton } from "./Modal";
import Cookies from 'js-cookie';

const TipsModalCookieName = 'seen_bc_tips_modal';

class TipsAndTricksModal extends React.Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    if (!Cookies.get(TipsModalCookieName)) this.modalRef.current.open();
  }

  render() {
    return (
      <Modal
        ref={this.modalRef}
        className="modal-tips-and-tricks"
        trigger="Check out our Tips & Tricks"
        title="Welcome to Brand Center"
        buttons={[
          <ModalButton
            label="GET STARTED"
            onPush={() => { this.modalRef.current.close(true); }}
          />
        ]}
        onClose={() => { Cookies.set(TipsModalCookieName, 'true'); }}
      >
        <p>Bring the Owens Corning brand to life in your marketing and communication projects by using Brand Center assets. The Brand Center is here to help with photography, iconography, illustrations, panthers, logos, and more.</p>
        <p>Here are some search tips and tricks to improve your search results:</p>
        <ul>
          <li>When searching for something specific, try <b>using quotes</b> (e.g., “man on a roof”)</li>
          <li>Try <b>check spelling</b> or use <b>alternate spellings</b> (e.g., “log” vs. “logo” or “gray” vs. “grey”)</li>
          <li><b>Type in * or all</b> to view all the assets within the Brand Center</li>
          <li><b>Start simple and gradually add search terms.</b> The best method is to start with something simple, then get more complicated</li>
        </ul>
        <img className="modal-tips-panther" src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1595339549/brand-center/tips-and-tricks-panther.png" />
      </Modal>
    );
  }
}

export default TipsAndTricksModal;
