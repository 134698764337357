import { Provider } from 'react-redux';

import { store } from '../Store';
import Video from './oc-video';

const App = (props) => (
  <Provider store={ store }>
    <Video { ...props } />
  </Provider>
);

export default App;
