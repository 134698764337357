import React from "react";

export class AssetDropdown extends React.Component {

  // PROPS

  // title        -   Title for dropdown toggle area
  // choices      -   List of choices to select in dropdown
  // multiselect  -   Allow multiple selections
  // onChoice     -   Callback for responding to a selection

  constructor(props) {
    super(props);
    this.reference = React.createRef();

    this.state = {
      open: false,
      selected: []
    };

    this.onClickOutside = this.onClickOutside.bind(this);

    this.openDropdown = this.openDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.onToggleDropdown = this.onToggleDropdown.bind(this);

    this.onToggleDropdown = this.onToggleDropdown.bind(this);
    this.onToggleSelect = this.onToggleSelect.bind(this);
    this.onChoice = this.onChoice.bind(this);
  }


  // Handle clicks outside of dropdown, and close if it happens

  componentDidMount() {
    document.addEventListener("mousedown", this.onClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onClickOutside);
  }

  onClickOutside(event) {
    if (this.reference && !this.reference.current.contains(event.target)) this.closeDropdown();
  }


  // Handle toggling dropdown open/closed

  openDropdown() {
    this.setState({ open: true });
  }

  closeDropdown() {
    this.setState({ open: false });
  }

  onToggleDropdown() {
    this.state.open ? this.closeDropdown() : this.openDropdown();
  }


  // Handle making a choice from the list

  onToggleSelect(event, choice) {
    if (this.state.selected.includes(choice)) {
      this.setState({ selected: this.state.selected.filter(option => option != choice), title: this.props.title }, this.onChoice);
    } else {
      if (this.props.multiselect) {
        this.setState({ selected: this.state.selected.concat([choice]) }, this.onChoice);
      } else {
        this.setState({ selected: [choice] }, this.onChoice);
      }
    }

    if (!this.props.multiselect) this.closeDropdown();
  }

  clearSelection() {
    this.setState({ selected: [] });
  }

  setSelected(selected) {
    this.setState({ selected: selected });
  }

  onChoice() {
    if (this.props.onChoice) this.props.onChoice(this.state.selected);
  }


  render() {
    return (
      <div ref={this.reference} className={`asset-dropdown${ this.state.open ? ' open' : '' }`} >
        <div className="asset-dropdown-title" onClick={this.onToggleDropdown} >{ this.props.title }{ !this.props.multiselect && this.state.selected.length ? <span>: <span className="asset-filter-marker" >{this.state.selected[0]}</span></span> : '' } <i className="fa fa-caret-down" aria-hidden="true" /></div>
        <div className="asset-dropdown-choices" >
          {
            this.props.choices.map((choice, index) => {
              const lbl = this.props.labels ? (this.props.labels[choice] ? this.props.labels[choice] : choice) : choice;
              return (
                <div key={index} className={`asset-dropdown-choice${ this.state.selected.includes(choice) ? ' chosen' : '' }`} onClick={((e) => this.onToggleSelect(e, choice))} data-track="filter" data-track-filter-name="Dropdown Filter" data-track-filter-option={lbl} >
                  <i className="fa fa-check" aria-hidden="true" />
                  {lbl}
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
};
