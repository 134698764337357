import React, { useEffect, useState, useRef } from "react";
import { Provider } from 'react-redux';
import { store } from "../../../Store";
import styled from "@emotion/styled";
import _ from "lodash";

// Assets
import IconChevron from "../../../ComponentLibrary/icons/icon-chevron";
import IconExclamationTriangle from "../../../ComponentLibrary/icons/icon-exclamation-triangle";
import IconInfo from "../../../ComponentLibrary/icons/icon-info";

// Components
import AlertMessage from '../../../OC/oc-alert-message';
import Button from "../../../ComponentLibrary/oc-button";
import TextInput from "../../../ComponentLibrary/input-elements/oc-text-input";
import BaseInput from "../../../ComponentLibrary/input-elements/oc-input-base";
import PhoneInput from "../../../ComponentLibrary/input-elements/oc-phone-input";
import SelectDropdown from "../../../ComponentLibrary/input-elements/oc-select-dropdown";
import Checkbox from "../../../ComponentLibrary/input-elements/oc-checkbox";
import FormButton from "../../../ComponentLibrary/input-elements/oc-form-button";
import StickyNav from "../../../OC/oc-sticky-nav";
import InfoMessage from "../../../ComponentLibrary/oc-info-message";
import Banner from "../../../ComponentLibrary/oc-banner";
import PricingBreakdown from "./PricingBreakdown";
import Contingencies from "./Contingencies";
import FileWidget from "@owenscorning/react-library";
import Label from "../../../ComponentLibrary/input-elements/oc-label";
import PricingCalculator from "./PricingCalculator";
import QuoteDetails from "./QuoteDetails";
import Modal from "../../../Modal";

// Styled Components
const AddPhoto = styled.a`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;

  span {
    display: inline-block;
    margin-left: 16px;
  }
`;

const FlexRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  max-width: 408px;
`;

const PhotoInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileName = styled.span`
  font-size: 14px;
  margin-bottom: 4px;
  color: #333333;
`;

const DeletePhotoIcon = styled.span`
  color: #585858;
  margin: 0px;
  cursor: pointer;
`;

const FileSize = styled.span`
  font-size: 12px;
  color: #585858;
`;

const Line = styled.div`
  border: 3px solid #085D0F;
  margin-bottom: 16px;
  width: 100%;
  max-width: 408px;
`;

const UploadContainer = styled.div`
  border-radius: 0.5em;
  border: 2px dashed #585858;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 24px
`;

const ConfigBanner = styled(Banner)`
  @media screen and (max-width: 767px) {
    margin-bottom: 32px;
  }
`;

const BannerName = styled.span`
  text-decoration: underline;
`;

const BannerLink = styled.span`
  color: #FFFFFF;
  cursor: pointer;

  &:hover {
    color: #D40F7D;
  }
`;

const DropdownContainer = styled.div`
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 394px;
  }
`;

const Section = styled.section`
  border-bottom: 2px solid #000000;
  margin-bottom: 64px;
  padding-bottom: 32px;

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const FormHeader = styled(Section)`
  border-bottom: 0;
  padding-bottom: 0;
`;

const SectionTitle = styled.h3`
  margin-bottom: 16px;

  & + div {
    margin-top: 16px;
  }
`;

const SectionCopy = styled.p`
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 32px;
  }

  &.uniq {
    margin-bottom: 16px;
  }
`;

const SectionNote = styled.span`
  display: inline-block;
  font-size: 12px;
  line-height: 17px;
  padding-top: 16px;
  position: relative;
  width: 100%;
`;

const FormSection = styled.div`
  display: inline-block;
  margin-bottom: 32px;
  position: relative;
  width: 100%;

  &.split {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Disclaimer = styled.p`
  align-items: center;
  color: #6D6D6D;
  display: flex;
  font-size: 12px;
  line-height: 17px;

  svg {
    margin-right: 8px;
  }
`;

const ProductCheckbox = styled(Checkbox)`
  margin-top: 8px;
`;

const ContainerHalf = styled.div`
  display: inline-block;
  position: relative;
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  &:first-of-type {
    @media screen and (max-width: 768px) {
      margin-bottom: 32px;
    }
  }
`;

const H4 = styled.h4`
  text-transform: ${props => props.uppercase ? "uppercase" : "none"};
`;

const DetailHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid #959595;
  display: flex;
  position: relative;

  h6 {
    flex-grow: 1;
    margin-bottom: 8px;
  }
`;

const LocationDetails = styled.div`
  align-items: center;
  background-color: #F8F8F8;
  display: flex;
  justify-content: space-between;
  padding: 26px;
  position: relative;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const LocationDetail = styled.div`
  display: inline-block;
  position: relative;
  width: 42%;

  @media screen and (max-width: 767px) {
    margin-bottom: 1em;
    width: 100%;
  }
`;

const LocationDescription = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 1em;
  position: relative;
`;

const LocationImage = styled.div`
  background-image: url('${props => props.imageUrl}');
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  padding-bottom: 62%;
  position: relative;
  width: 100%;
`;

const Vs = styled.h3`
  display: inline-flex;
  position: relative;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
    padding-left: 26px;
    padding-right: 26px;
  }

  @media screen and (max-width: 767px) {
    justify-content: center;
    margin-bottom: 1em;
    width: 100%;
  }
`;

const RadioLabel = styled.label`
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 16px;
  position: relative;
  width: 100%;

  input[type="radio"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #D1D1D1;
    flex-shrink: 0;
    height: 16px;
    margin: 1px 8px 0 0;
    width: 16px;

    & ~ .radio {
      background: #D40F7D;
      border-radius: 6px;
      display: none;
      height: 8px;
      left: 4px;
      position: absolute;
      top: 5px;
      width: 8px;
    }

    &:checked {
      background-color: white;
      border-color: #D40F7D;

      & ~ .radio {
        display: inline-block;
      }
    }
  }
`;

const Toggle = styled.div`
  align-items: center;
  display: inline-flex;
  position: relative;
  width: 100%;

  input[type="checkbox"] {
    display: none;

    &:checked + label {
      border-color: #D40F7D;

      &:after {
        background-color: #D40F7D;
        left: calc(100% - 2px);
        transform: translateX(-100%);
      }
    }
  }

  label {
    background-color: transparent;
    border: 1px solid #6D6D6D;
    border-radius: 20px;
    cursor: pointer;
    display: block;
    height: 25px;
    margin-right: 16px;
    position: relative;
    text-indent: -9999px;
    width: 45px;

    &:after {
      background-color: #6D6D6D;
      border-radius: 20px;
      content: '';
      height: 19px;
      left: 2px;
      position: absolute;
      top: 2px;
      transition: 0.3s;
      width: 19px;
    }

    &:active:after {
      width: 25px;
    }
  }
`;

const ToggleOption = styled.span`
  display: inline-block;
  position: relative;
  margin-right: 16px;
`;

const GetStartedPreview = styled.div`
  background-image: url('${props => props.imageUrl}');
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  padding-bottom: 72%;
  position: relative;
  width: 100%;
`;

const QueryParams = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  span {
    line-height: 22px;
  }
`;

const PseudoTab = styled.div`
  align-items: center;
  border-bottom: 4px solid #D40F7D;
  color: #D40F7D;
  display: inline-flex;
  margin-bottom: 24px;
  padding: 16px 8px;
  position: relative;
  text-transform: uppercase;

  svg {
    margin-right: 8px;
  }
`;

const EmbedStep = styled.div`
  display: inline-block;
  margin-bottom: 24px;
  position: relative;
  width: 100%;
`;

const CodeBlock = styled.pre`
  background-color: #E6E6E6;
  display: inline-block;
  margin: 0 0 16px;
  overflow-x: scroll;
  padding: 8px 16px;
  position: relative;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const FormFooter = styled.div`
  display: flex;
  margin: 0 auto 64px;
  justify-content: space-between;
  max-width: 1170px;
  padding-bottom: 32px;
  position: relative;
  width: 100%;

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    max-width: 400px;

    button {
      margin-bottom: 16px;
    }
  }
`;

const FooterContainer = styled.div`
  display: inline-flex;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column
  }

  button {
    margin-right: 24px;

    @media screen and (max-width: 1199px) {
      margin-right: 12px;
    }

    @media screen and (max-width: 767px) {
      margin-right: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const CtaChevron = styled.span`
  display: inline-block;
  height: 8px;
  left: -3px;
  margin-right: 10px;
  position: relative;
  transform: rotate(90deg);
  width: 14px;

  svg {
    height: 8px;
    left: 0;
    position: absolute;
    top: 0;
    width: 14px;
  }
`;

const ModalTitle = styled.h3`
  margin-bottom: 24px;
`;

const ModalH3 = styled(ModalTitle)`
  font-size: 24px;
`;

const ModalHeader = styled.div`
  border-bottom: 2px solid #000000;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 24px;
  width: 100%;

  h4 {
    width: 100%;
  }
`;

const HeaderDetail = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 24px;
  margin-right: 16px;

  strong {
    font-size: 18px;
  }
`;

const ModalImage = styled.div`
  background-image: url('${props => props.imageUrl}');
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  padding-bottom: 62%;
  position: relative;
  width: 100%;
`;

const PricingModalImage = styled(ModalImage)`
  padding-bottom: 54%;
`;

const StandOutModalImage = styled(ModalImage)`
  padding-bottom: 90%;
`;

const PricingFormulaExample = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    padding: 10px;
    span {
      color: #6D6D6D;
    }

    &.markup {
      background-color: #F8F8F8;
      border: 1px dashed #6D6D6D;
    }

    &.total-pricing {
      color: #D40F7D;
    }
  }
`;

const PricingWarning = styled.h2`
  text-transform: uppercase;
  font-size: 2.5rem;
  transform: translateY(-3px);
  margin-bottom: 1rem;
`

const PricingWarningModalBody = styled.p`
  margin-top: 0;
  margin-bottom: 1.5rem;
`

const BackButton = ({ onClick }) => {
  return (
    <FormButton color="white" onClick={(event) => onClick(event)}>
      <CtaChevron><IconChevron height="8px" width="14px" /></CtaChevron>
      Back to Configurations
    </FormButton>
  );
}

const PricingModalContent = () => {
  return (
    <>
      <ModalTitle>How does automatic pricing work?</ModalTitle>
      <ModalHeader>
        <h4>Formula breakdown</h4>
        <p>
          Sq. Ft. of Home &times; Pitch/Overhang Factor &times; Price per Sq.Ft. of
          Home &times; Percent Markup = Estimate Price Range
        </p>
      </ModalHeader>
      <h4>Example 1</h4>
      <PricingFormulaExample>
        <p>
          <strong>2,500</strong>
          <br />
          <span>Sq. Ft. of Home</span>
        </p>
        <p>
          <strong>&times;</strong>
        </p>
        <p>
          <strong>1.75</strong>
          <br />
          <span>Pitch/Overhang Factor</span>
        </p>
        <p>
          <strong>&times;</strong>
        </p>
        <p>
          <strong>$3.25</strong>
          <br />
          <span>Price per Sq. Ft. of Home</span>
        </p>
        <p>
          <strong>&times;</strong>
        </p>
        <p className="markup">
          <strong>0%</strong>
          <br />
          <span>Percent Markup</span>
        </p>
        <p>
          <strong>&#61;</strong>
        </p>
        <p className="total-pricing">
          <strong>$14,218</strong>
          <br />
          <span>Estimate Base Price</span>
        </p>
      </PricingFormulaExample>
      <h4>Example 2</h4>
      <PricingFormulaExample>
        <p>
          <strong>2,500</strong>
          <br />
          <span>Sq. Ft. of Home</span>
        </p>
        <p>
          <strong>&times;</strong>
        </p>
        <p>
          <strong>1.75</strong>
          <br />
          <span>Pitch/Overhang Factor</span>
        </p>
        <p>
          <strong>&times;</strong>
        </p>
        <p>
          <strong>$3.25</strong>
          <br />
          <span>Price per Sq. Ft. of Home</span>
        </p>
        <p>
          <strong>&times;</strong>
        </p>
        <p className="markup">
          <strong>10%</strong>
          <br />
          <span>Percent Markup</span>
        </p>
        <p>
          <strong>&#61;</strong>
        </p>
        <p className="total-pricing">
          <strong>$14,218 - $15,639</strong>
          <br />
          <span>Estimate Base Price</span>
        </p>
      </PricingFormulaExample>
    </>
  );
}

const UnavailableProductsModalContent = (serviceArea, unavailableProducts) => {
  return (
    <>
      <ModalTitle>Product(s) Unavailable</ModalTitle>
      {unavailableProducts.map((product) =>
        <>
          <p>
            <strong>{product}</strong> is unavailable in one or more of the
            following zip codes you have listed in your service area:
          </p>
          <ul>
            {serviceArea.map((zipCode) =>
              <li>{zipCode}</li>
            )}
          </ul>
        </>
      )}
    </>
  );
};

const PricingWarningModalButton = styled(Button)`
  outline: none;
`

const EditForm = ({
  activeConfiguration,
  setActiveConfiguration,
  setIsEditing,
  updateData,
  saveConfiguration,
  saving,
  openModal,
  closeModal,
  shingles,
  componentProducts,
  configurations,
  handleWarningChange,
  showPricingWarning,
  showWarningModal,
  closeWarningModal,
  openWarningModal
}) => {
  const getStartedImageUrl = "https://imagecdn.owenscorning.com/ocimages/image/upload/v1668485611/roofing/quoting-widget/qw-get-started.jpg";
  const formImageUrl = "https://imagecdn.owenscorning.com/ocimages/image/upload/v1668735625/roofing/quoting-widget/qw-form-start.jpg";
  const widgetLocationDirectUrl = "https://imagecdn.owenscorning.com/ocimages/image/upload/v1674074563/roofing/quoting-widget/widget-location-direct.jpg";
  const widgetLocationIndirectUrl = "https://imagecdn.owenscorning.com/ocimages/image/upload/v1674074563/roofing/quoting-widget/widget-location-indirect.jpg";
  const indirectWidgetEmbed = activeConfiguration.data["widgetLocation"] == "indirect-embed";
  const [errors, setErrors] = useState({});
  const [validServiceArea, setValidServiceArea] = useState(activeConfiguration.data["serviceArea"] || []);
  const [availableShinglesInArea, setAvailableShinglesInArea] = useState(shingles);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const firstProductLoad = useRef(false);
  const firstProductValidation = useRef(false);
  const photoValue = activeConfiguration.data["photoUpload"] || ""
  const [usePreviousStandouts, setUsePreviousStandouts] = useState(false);
  const [fileInfo, setFileInfo] = useState({});

  let validPreviousConfigurations = configurations?.filter(config => config.data["standOuts"]?.length && config.id != activeConfiguration.id);
  let validPreviousConfigurationOptions = [{ text: 'Select a Configuration', value: "" }];

  validPreviousConfigurations.map(configuration => {
    let option = {
      text: configuration.data.configurationName,
      value: configuration.id,
      standOuts: configuration.data.standOuts
    };

    validPreviousConfigurationOptions.push(option);
  });

  let defaultMapping = {
    "ice-water-barrier": "weatherlock_mat",
    "hip-ridge-shingles": "proedge_hip_ridge",
    "underlayment": "deck_defense",
    "starter-shingles": "starter_strip_plus",
    "exhaust-ventilation": "4_foot_strip_ridge_vent",
  }

  const updateServiceArea = (event) => {
    let config = _.cloneDeep(activeConfiguration);
    let textValue = event.target.value;
    let arrayValue = textValue.split(",").map(value => value.trim().replace(/\D/g, '')).filter(value => (value.length == 5));

    config.data["serviceAreaText"] = textValue;
    config.data["serviceArea"] = arrayValue;
    setActiveConfiguration(config);
    if (validServiceArea.toString() !== arrayValue.toString())
      setValidServiceArea(arrayValue);
  }

  const updatePhotoUpload = (newPhotoValue) => {
    updateData(event, "photoUpload", newPhotoValue);
    closeModal()
  };

  const deletePhotoUpload = () => {
    updateData(event, "photoUpload", '');
  };

  const setWidgetLocation = (event) => {
    let config = _.cloneDeep(activeConfiguration);
    let locationValue = event.target.value;

    config.data["widgetLocation"] = locationValue;
    config.data["showStartPage"] = locationValue == "direct-embed";
    config.data["allowDataPrefill"] = locationValue != "direct-embed";
    setActiveConfiguration(config);
  }

  const PhotoModalContent = () => {
    let modalPhoto = photoValue;
    return (
      <>
        <ModalTitle>Photo Upload</ModalTitle>
        <UploadContainer>
          {React.createElement(FileWidget.Connect.FileWidget, {
            multiple: false,
            onChange: files => (modalPhoto = files),
            value: photoValue,
            readOnly: false,
            server: MDMS_URL,
            mimeTypes: ['image/jpeg', 'image/png',],
            track: {
              upload: {
                track: 'upload',
                ['upload-type']: 'Image',
                ['upload-method']: 'Select File',
              },
              add: {
                track: 'upload',
                ['upload-type']: 'Image',
                ['upload-method']: 'Add Files',
              },
              drop: {
                track: 'upload',
                ['upload-type']: 'Image',
                ['upload-method']: 'Drag and Drop',
              },
              remove: {
                track: 'delete',
                ['upload-type']: 'Image',
              },
            }
          })}
        </UploadContainer>
        <FormButton onClick={() => updatePhotoUpload(modalPhoto)}>Save Upload</FormButton>
      </>
    );
  }

  const backToList = () => {
    if (showPricingWarning) {
      openWarningModal();
      return;
    }

    setIsEditing(false);
    window.scrollTo({
      behavior: "smooth",
      top: 0
    });
  }

  const validateAndUpdateData = (event, field, value = null) => {
    if (event.target.checkValidity() && field === "companyWebsite") {
      setErrors({});
    }
    if (!event.target.checkValidity() && field === "companyWebsite") {
      setErrors({ "company-website": "The URL is not a valid format." })
    }

    updateData(event, field, value);
  };

  const updateProductData = (event, category) => {
    let config = _.cloneDeep(activeConfiguration);
    let productUid = event.target.value;
    let product = componentProducts[category].find(product => product["uid"] == productUid)

    config.data[category]["uid"] = productUid;
    config.data[category]["product"] = product;
    setActiveConfiguration(config);
  };

  const toggleProductDisplay = (event, category) => {
    let config = _.cloneDeep(activeConfiguration);
    let value = event.target.checked;

    config.data[category]["display"] = !value;
    config.data["noComponentProductsShown"] = Object.keys(componentProducts).every(category => !config.data[category]?.["display"]);
    setActiveConfiguration(config);
  };

  const copySelectedStandoutsToConfig = (event) => {
    let selectedConfigId = event.target.value;
    let selectedConfig = validPreviousConfigurationOptions.find(option => option.value == selectedConfigId);

    updateData(event, "standOuts", selectedConfig.standOuts);
  }

  const SaveButton = ({ saveConfiguration, disabled }) => {
      return (
        <FormButton disabled={disabled} onClick={() => saveConfiguration()}>
          Save Configuration
        </FormButton>
      )
  }

  const stickyNavItems = [
    { heading: "Configuration Name", anchor: "config-name" },
    { heading: "Company Information", anchor: "config-company" },
    { heading: "Product Selection", anchor: "product-selection" },
    // { heading: "Shingle Selection", anchor: "config-shingles" },
    { heading: "Pricing", anchor: "config-pricing" },
    { heading: "Quote Details", anchor: "config-quote-details" },
    { heading: "Installation Instructions", anchor: "config-embed" }
  ];

  const isSelectedShingleAvailable = () => {
    if (activeConfiguration.data["allowedShingle"] === undefined)
      return true;

    return availableShinglesInArea.some(shingle => shingle.value === activeConfiguration.data["allowedShingle"])
  };

  const handleWarningModalButtonClick = () => {
    const element = document.getElementById('config-pricing');
    if (element) {
      const rect = element.getBoundingClientRect();
      window.scrollTo({
        top: (rect.top + window.scrollY) - 112,
        behavior: 'smooth'
      });
    }
    closeWarningModal();
  };

  useEffect(() => {
    if (activeConfiguration.data["allowedShingle"] === undefined)
      updateData(null, "allowedShingle", shingles[0].value);
    if (validServiceArea.length <= 0) {
      setAvailableShinglesInArea(shingles);
      return;
    }

    setIsLoadingProducts(true);
  }, [validServiceArea]);

  useEffect(() => {
    if (!isLoadingProducts)
      return;

    const zipCodes = validServiceArea.toString();
    const shinglesUrl = `${MDMS_URL}/api/v2/product/shingles?multi_zone=true&zip_codes=${zipCodes}`;

    fetch(shinglesUrl).then(
      (response) => response.json()
    ).then(
      (responseJSON) => {
        const shingles = responseJSON.data.map((shingle) => {
          return {
            text: shingle.full_proper_name,
            value: shingle.uid,
          };
        });
        setAvailableShinglesInArea(shingles);
      }
    ).catch(function() {
      console.log("Error loading shingle colors");
      setAvailableShinglesInArea(shingles);
    }).finally(() => {
      setIsLoadingProducts(false);
      firstProductLoad.current = true;
    });
  }, [isLoadingProducts]);

  useEffect(() => {
    if (photoValue) {
      let file_info_url = `${MDMS_URL}/api/v1/roofing/quoting_widget/get_file_info?file_s3_url=${photoValue}}`;
      fetch(file_info_url)
        .then((response) => response.json())
        .then((responseJSON) => {
          const file_info = {
            name: responseJSON.file_name,
            size: responseJSON.file_size,
          };

          setFileInfo(file_info);
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  }, [photoValue]);

  useEffect(() => {
    if (!firstProductLoad.current)
      return;

    if (!firstProductValidation.current) {
      firstProductValidation.current = true;
      return;
    }

    const unavailableProducts = []
    if (!isSelectedShingleAvailable()){
      const shingleText = shingles.find((shingle) =>
        shingle.value === activeConfiguration.data["allowedShingle"]
      ).text;
      unavailableProducts.push(shingleText);
    }

    if (unavailableProducts.length > 0)
      openModal(UnavailableProductsModalContent(validServiceArea, unavailableProducts), "lg");


  }, [availableShinglesInArea]);


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (showPricingWarning) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [showPricingWarning]);


  const componentProductOptions = (category) => {
    let options = componentProducts[category]?.map(product => (
      { text: product["proper_name"], value: product["uid"] }
    ));

    const foundIndex = options.findIndex(item => item.value === defaultMapping[category]);

    if (foundIndex !== -1) {
      const foundItem = options.splice(foundIndex, 1)[0];
      options.unshift(foundItem);
    }

    return options;
  };

  // Set default values for required inputs on first load only
  // if config is missing value for that input
  useEffect(() => {
    let config = _.cloneDeep(activeConfiguration);

    Object.keys(componentProducts).map(key => {
      if (!activeConfiguration.data[key]) {

        if (componentProducts[key].length === 0) {
            config.data[key] = {};
            config.data[key]["display"] = false;
        }

        componentProducts[key].forEach((item, index) => {
          if (defaultMapping[key] === (item["uid"])) {
            config.data[key] = {};
            config.data[key]["category"] = item["categories"][0]["proper_name"];
            config.data[key]["uid"] = item["uid"];
            config.data[key]["product"] = item;
            config.data[key]["display"] = true;
            config.data["noComponentProductsShown"] = false;
          } else if (!config.data[key] && index === componentProducts[key].length - 1){
            config.data[key] = {};
            config.data[key]["category"] = componentProducts[key][0]["categories"][0]["proper_name"];
            config.data[key]["uid"] = componentProducts[key][0]["uid"];
            config.data[key]["product"] = componentProducts[key][0];
            config.data[key]["display"] = true;
            config.data["noComponentProductsShown"] = false
          }
        })
      }
    });

    setActiveConfiguration(config);
  }, []);

  return (
    <Provider store={store}>
      <StickyNav cta={<SaveButton saveConfiguration={saveConfiguration} disabled={saving || isLoadingProducts} />} items={stickyNavItems}>
        <ConfigBanner>
          You are Currently Editing Configuration <BannerName>{activeConfiguration.data["configurationName"]}</BannerName>. To exit, please save the configuration edits first and then go <BannerLink onClick={() => backToList()}>BACK TO CONFIGURATIONS</BannerLink>.
        </ConfigBanner>

        <FormHeader>
          <BackButton onClick={backToList} />
        </FormHeader>

        {/* Configuration name & location */}
        <Section id="config-name">
          <FormSection>
            <H4>Configuration Name</H4>
            <TextInput
              onChange={(event) => updateData(event, "configurationName")}
              value={activeConfiguration.data["configurationName"]}
            />
          </FormSection>
        </Section>

        {/* Company name and contact info */}
        <Section id="config-company">
          <SectionTitle>Company Information</SectionTitle>
          {/* <SectionCopy>Add section copy here.</SectionCopy> */}

          <FormSection>
            <H4>Your Company Name</H4>
            <TextInput
              onChange={(event) => updateData(event, "companyName")}
              value={activeConfiguration.data["companyName"]}
            />
          </FormSection>

          <FormSection>
            <H4>Company Web Address</H4>
            <p>This web address will be provided to the user on the email copy of the completed estimate in order to contact you.</p>
            <BaseInput
              error={errors["company-website"]}
              id="company-website"
              inputType="url"
              name="company-website"
              onChange={(event) => validateAndUpdateData(event, "companyWebsite")}
              placeholder="https://www.roofing.com"
              value={activeConfiguration.data["companyWebsite"]}
              touched={activeConfiguration.data["companyWebsite"]?.length > 0}
            />
            <Disclaimer>
              <IconInfo />
              Please include protocol (https://) when entering URL
            </Disclaimer>
          </FormSection>

          <FormSection>
            <H4>Email Address for Leads</H4>
            <p>This is the email address where leads will be sent when the user successfully completes the embedded Budget Your Roof Widget form. Add multiple email addresses by separating each email with a comma.</p>
            <TextInput
              onChange={(event) => updateData(event, "leadsEmail")}
              value={activeConfiguration.data["leadsEmail"]}
            />
            <Disclaimer>
              Only users with OCConnect accounts will be able to view full quote details
            </Disclaimer>
          </FormSection>

          <FormSection>
            <H4>Email Address for Contact</H4>
            <p>This is the email address that we will provide to the customer in order to reach you. Leaving this blank will direct them to your Leads email above instead.</p>
            <TextInput
              onChange={(event) => updateData(event, "communicationsEmail")}
              value={activeConfiguration.data["communicationsEmail"]}
            />
          </FormSection>

          <FormSection>
            <H4>Phone Number for Homeowners to Call</H4>
            <p>This is the phone that we will provide to the customer to reach you.</p>
            <PhoneInput
              onChange={(event) => updateData(event, "companyPhone")}
              placeholder="(___) ___-____"
              value={activeConfiguration.data["companyPhone"] || ""}
            />
          </FormSection>

        </Section>

        {/* Product selection */}
        <Section id="product-selection">
          <SectionTitle>Product Selection</SectionTitle>
          <SectionCopy>Select the products that will be included in your quote estimate.</SectionCopy>
          <FormSection>
            <SelectDropdown
              label="Shingle Line"
              required
              defaultValue={activeConfiguration.data["allowedShingle"]}
              onChange={(event) => updateData(event, "allowedShingle", event.target.value)}
              options={shingles}
            />
            { !isSelectedShingleAvailable() &&
              <Disclaimer>
                <IconExclamationTriangle fill="#D65900" height="14px" width="16px" />
                The following product is unavailable in the following zip code(s):&nbsp;
                {validServiceArea.toString()}
              </Disclaimer>
            }
          </FormSection>

          {Object.keys(componentProducts).map(category => (
            <FormSection key={`${category}-selector`}>
              <SelectDropdown
                label={activeConfiguration.data[category]?.["category"]}
                defaultValue={activeConfiguration.data[category]?.["uid"]}
                disabled={!activeConfiguration.data[category]?.["display"]}
                onChange={(event) => updateProductData(event, category)}
                options={componentProductOptions(category)}
                required
              />

              <ProductCheckbox
                checked={!activeConfiguration.data[category]?.["display"]}
                name={`${category}-display`}
                onChange={(event) => toggleProductDisplay(event, category)}
              >
                Do not show product line
              </ProductCheckbox>
            </FormSection>
          ))}

          {activeConfiguration.data["noComponentProductsShown"] &&
            <InfoMessage message="We noticed you do not have any roofing components selected. Our research shows homeowners feel more confident in the roof quality when roofing components are included in their roof." />
          }
        </Section>

        {/* Pricing */}
        <Section id="config-pricing">
          <PricingCalculator configuration={activeConfiguration} updateData={updateData} />
          <PricingBreakdown
            configuration={activeConfiguration}
            updateData={updateData}
            onPricingWarningChange={handleWarningChange}
            showPricingWarning={showPricingWarning}
          />
          <Disclaimer>
            *Values entered are used in the pricing formula to display an estimated budget range. Please review all values entered before saving. Owens Corning is not responsible for any value input errors.
          </Disclaimer>
        </Section>

        {/* Complete Quote Details */}
        <Section id="config-quote-details">
          <QuoteDetails configuration={activeConfiguration} updateData={updateData} />
        </Section>

        {/* Widget embed code */}
        <Section id="config-embed">
          <SectionTitle>Website Installation Instructions</SectionTitle>

          {showPricingWarning &&
            <SectionCopy>
              <AlertMessage
                alertIcon
                message='All values in the customized price settings must be entered before publishing a widget to your website.'
                title="Enter Pricing Values"
                type='warning'
              />
            </SectionCopy>
          }

          <EmbedStep>
            <H4>Step 1 of 2: Add Javascript</H4>
            <p>Include this javascript in the &lt;body&gt; section of your website. We recommend that it is placed right before the final &lt;/body&gt; tag.</p>
            <CodeBlock>
              &lt;script src="{location.origin}/en-us/widgets/budget-your-roof.js?configuration-id={activeConfiguration.id}"&gt;&lt;/script&gt;
            </CodeBlock>
          </EmbedStep>

          <EmbedStep>
            <H4>Step 2 of 2: Add Widget DIV</H4>
            <p>Include the Budget Your Roof Widget embed &lt;div&gt; on the page where you would like the widget to show.</p>
            <CodeBlock>
              &lt;div id="budget-your-roof-embed"&gt;&lt;/div&gt;
            </CodeBlock>
          </EmbedStep>
        </Section>

        <FormFooter>
          <BackButton onClick={backToList} />

          <FooterContainer>
            <FormButton disabled={saving || Object.keys(errors).length > 0 || isLoadingProducts} onClick={() => saveConfiguration()}>
              Save Configuration
            </FormButton>
            {/* <FormButton color="white" onClick={}>
              Preview Widget
            </FormButton> */}
          </FooterContainer>
        </FormFooter>
      </StickyNav>
      <Modal open={showWarningModal}  onClose={() => closeWarningModal()}>
        <PricingWarning>Empty Price Settings</PricingWarning>
        <PricingWarningModalBody>
          Please enter your customized price settings before publishing your widget.
          The entered values will be used to calculate the price shared with homeowners.
        </PricingWarningModalBody>
        <PricingWarningModalButton onClick={handleWarningModalButtonClick}>
          Edit Pricing
        </PricingWarningModalButton>
        </Modal>
    </Provider>
  );
}

export default EditForm;
