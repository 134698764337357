import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from '@emotion/styled';

// Components
import Label from "../../../ComponentLibrary/input-elements/oc-label";
import NumberFormat from "react-number-format";
import { H3, H5 } from '../../../OC/oc-h';

// Functions
import { maxPriceValidator, minPitchFactor, markupValidator, presentMoneyRange } from "../../QuotingWidget/functions/sharedFunctions";

// styled components
const Section = styled.section`
  border-bottom: 2px solid #000000;
  margin-bottom: 64px;
  padding-bottom: 32px;
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const SectionTitle = styled.h3`
  margin-bottom: 16px;
  & + div {
    margin-top: 16px;
  }
`;

const SectionCopy = styled.p`
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 32px;
  }
  &.uniq {
    margin-bottom: 16px;
  }
`;

const FormSection = styled.div`
  display: inline-block;
  margin-bottom: 32px;
  position: relative;
  width: 100%;
  &.split {
    display: flex;
    flex-wrap: wrap;
  }
`;

const EditContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    margin: 0 4px;
    color: #D40F7D;
  }
`;
const AddNewContainer = styled.div`
  cursor: pointer;
  span {
    margin: 0 4px;
  }
`;

const Table = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 12px 0px 24px 0px;
  width: 100%;
  &:nth-of-type(odd) {
    background-color: #F8F8F8;
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    padding: 24px 28px;
  }
`;

const Data = styled.div`
  display: inline-block;
  flex-shrink: 0;
  padding-left: ${props => props.paddingLeft || "12px"};
  padding-right: 12px;
  position: relative;
  width: ${props => props.width || "25%"};
  @media screen and (max-width: 767px) {
    margin-bottom: 1em;
    width: 100%;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &.markup-value {
    color: #6D6D6D;
    strong {
      display: inline-block;
      margin-bottom: 12px;
      width: 100%;
    }
    span {
      display: inline-block;
      padding: 14px 8px;
    }
  }
  h4 {
    margin-bottom: 0;
  }
`;

const SectionHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid #959595;
  display: flex;
  position: relative;
  h6 {
    flex-grow: 1;
    margin-bottom: 8px;
  }
`;

const Input = styled(NumberFormat)`
  appearance: none;
  border-radius: 0;
  border: 1px solid #585858;
  display: inline-block;
  font-size: 16px;
  margin-bottom: ${props => props.marginBottom || 0};
  padding: 14px 8px;
  position: relative;
  width: 100%;

  &:focus {
    border-color: #d40f7d;
    outline: none;
  }
`;

const Descriptor = styled.span`
  font-size: 12px;
  line-height: 17px;
`;

const MathOperator = styled.span`
  margin: 0 8px;
  font-size: 18px;
  padding-top: 24px;

  @media screen and (max-width: 767px) {
    padding-top: 0px;
  }
`;

const Estimate = styled.span`
  appearance: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: ${props => props.marginBottom || 0};
  padding: 14px 0px;
  position: relative;
  width: 100%;
  white-space: nowrap;
`;

const PricingCalculator = ({ configuration, updateData, openModal }) => {
  const [calculatorValues, setCalculatorValues] = useState({
    area: undefined,
    pitchFactor: undefined,
    price: undefined,
    markup: undefined,
    estimate: { lowEnd: 0, highEnd: 0}
  });

  const updateCalculator = (event, field) => {
    const { floatValue } = event;
    setCalculatorValues({ ...calculatorValues, [field]: floatValue });
  };

  const calculateEstimate = () => {
    const { area, pitchFactor, price, markup } = calculatorValues;

    // Check if all required values are defined and not zero
    if (area && pitchFactor && price) {
      let lowEnd = Math.round(area * pitchFactor * price);
      let highEnd = lowEnd;

      // If markup percentage is provided, adjust the high end of the range
      if (markup !== undefined && markup !== 0) {
          highEnd = Math.round(highEnd * (1 + markup / 100)); // Convert markup percentage to decimal
      }

      return { lowEnd, highEnd };
    }

    return { lowEnd: 0, highEnd: 0 };
  }

  useEffect(() => {
    setCalculatorValues(prevValues => ({
        ...prevValues,
        estimate: calculateEstimate()
    }));
  }, [calculatorValues.area, calculatorValues.pitchFactor, calculatorValues.price, calculatorValues.markup]);

  return (
    <>
      <SectionTitle>Pricing</SectionTitle>
      <SectionCopy>
        The following formula is used to calculate the price that will be reflected back to homeowners. Use the example calculator below to explore the calculations behind the price formula.
      </SectionCopy>
      <FormSection>
        <SectionHeader>
          <h6>
            Example Calculator
          </h6>
        </SectionHeader>
        <Table>
          <Row>
            <Data width="18%">
              <Label
                text="Sq. Ft. of Home"
                tooltip="The square footage of the home will be provided by the homeowner."
                required={true}
              />
              <Input
                allowEmptyFormatting={false}
                allowNegative={false}
                decimalScale={2}
                onValueChange={(event) => updateCalculator(event, "area")}
                thousandSeparator={true}
                value={calculatorValues.area}
                required={true}
              />
            </Data>
            <MathOperator>x</MathOperator>
            <Data width="18%">
              <Label
                text="Pitch/Overhang Factor"
                tooltip="The pitch/overhang factor helps ensure the entire roof surface area is accounted for."
                required={true}
              />
              <Input
                allowEmptyFormatting={false}
                allowNegative={false}
                decimalScale={2}
                isAllowed={(values) => minPitchFactor(values)}
                onValueChange={(event) => updateCalculator(event, "pitchFactor")}
                thousandSeparator={false}
                value={calculatorValues.pitchFactor}
                required={true}
              />
            </Data>
            <MathOperator>x</MathOperator>
            <Data width="19%">
              <Label
                text="Price Per Sq. Ft. of Home"
                required={true}
              />
              <Input
                allowEmptyFormatting={true}
                allowNegative={false}
                decimalScale={2}
                isAllowed={(values) => maxPriceValidator(values, 999)}
                onValueChange={(event) => updateCalculator(event, "price")}
                thousandSeparator={false}
                value={calculatorValues.price}
                required={true}
                prefix="$"
              />
            </Data>
            <MathOperator>x</MathOperator>
            <Data width="18%">
              <Label
                text="Percent Markup"
                tooltip="Add a percent markup to provide the homeowner with an estimate range."
              />
              <Input
                allowEmptyFormatting={false}
                allowNegative={false}
                decimalScale={2}
                isAllowed={(values) => markupValidator(values)}
                onValueChange={(event) => updateCalculator(event, "markup")}
                thousandSeparator={false}
                value={calculatorValues.markup}
                required={false}
                suffix="%"
              />
            </Data>
            <MathOperator>=</MathOperator>
            <Data width="15%">
              <Label
                text="Price Estimate"
              />
              <Estimate>{presentMoneyRange(calculatorValues.estimate?.lowEnd, calculatorValues.estimate?.highEnd, false)}</Estimate>
            </Data>
          </Row>
        </Table>
      </FormSection>
    </>
  );
};
export default PricingCalculator;
