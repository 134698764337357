import React from "react";

import { ApplicationSelector, FormatSelector } from './Specifications';
import { DownloadOptionList } from './Downloads';

const IndexView = ({ application, format, asset, options, selected, onApplicationChange, onFormatChange, onOptionSelect }) => {
  return (
    <div className="asset-download" >
      <ApplicationSelector selected={application} options={options} onChange={onApplicationChange} />
      <FormatSelector selected={format} formats={options[application].downloads} onChange={onFormatChange} />
      <DownloadOptionList asset={asset} options={options[application].downloads[format]} selected={selected} onSelect={onOptionSelect} />
      <a
        target="_blank"
        className={`oc-cta-button asset-download-cta${selected == -1 ? ' asset-download-cta-none' : ''}`}
        href={selected > -1 ? options[application].downloads[format][selected].url : ''}
        onClick={((e) => { if (selected == -1) e.preventDefault(); })}
        data-track="file-download"
        data-track-file-name={application}
        data-track-doc-type={asset.category}
        data-track-file-type={options[application].downloads[format][selected].key}
      >
        DOWNLOAD FILE
      </a>
    </div>
  );
};

class IndexController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      application: props.application,
      format: props.format,
      selected: 0
    };

    this.onApplicationChange = this.onApplicationChange.bind(this);
    this.onFormatChange = this.onFormatChange.bind(this);
    this.onOptionSelect = this.onOptionSelect.bind(this);
  }

  onApplicationChange(e) {
    this.setState({
      application: e.target.value,
      format: Object.keys(this.props.options[e.target.value].downloads)[0],
      selected: 0
    });
  }

  onFormatChange(e) {
    this.setState({
      format: e.target.value,
      selected: 0
    });
  }

  onOptionSelect(e, selected) {
    this.setState({ selected });
  }

  render() {
    return (
      <IndexView
        {...this.props}
        {...this.state}
        onApplicationChange={this.onApplicationChange}
        onFormatChange={this.onFormatChange}
        onOptionSelect={this.onOptionSelect}
      />
    );
  }
}

export default IndexController;
