import React from "react";

import { BrowserRouter, withRouter } from 'react-router-dom'

// IE11 Support

import 'promise-polyfill/src/polyfill';
import 'unfetch/polyfill';
import 'abortcontroller-polyfill';

import { FilterCategoryList, FilterSubsetList } from './Filters';
import { AssetViewToggle, AssetPageControl, AssetList } from './Assets';
import { AssetDropdown } from './Dropdown';
import Query from 'qs';

const AssetCategories = [
  { id: "all", name: "All", icon: "folder-open" },
  { id: "Photograph", name: "Photography", icon: "picture-o" },
  { id: "Graphic", name: "Graphics", icon: "file-image-o" },
  { id: "Video", name: "Videos", icon: "file-video-o" },
  { id: "Document", name: "Documents", icon: "file-text-o" }
];

const FilterLabels = {
  ApprovedChannelUsage: 'Approved Channel Usage',
  AssetRights: 'Asset Rights',
  AssetSensitivity: 'Asset Sensitivity',
  AssetAttire: 'Attire',
  AssetBuilding: 'Building/Structure',
  AssetBusinessUnits: 'Business Units',
  AssetCertification: 'Certifications',
  AssetColor: 'Color',
  AssetColorPalette: 'Color Palette',
  AssetCreator: 'Credits',
  AssetEthnicity: 'Ethnicity',
  AssetExpirationDate: 'Expiration Date',
  AssetFeaturedColors: 'Featured Colors',
  AssetCompanies: 'Featured Companies/Customers',
  AssetFeaturedMaterials: 'Featured Materials',
  AssetGender: 'Gender',
  AssetNamedLocation: 'Location',
  AssetNamedIndividual: 'Named Individuals',
  AssetPeople: 'People',
  AssetPersona: 'Persona',
  AssetPhotoContext: 'Photo Context',
  AssetProductBrands: 'Product Brands',
  AssetProducts: 'Products',
  AssetProductTypes: 'Product Types',
  AssetPrograms: 'Programs',
  MARCOMProjectName: 'Project Name',
  AssetRecommendedUse: 'Recommended Use',
  AssetSetting: 'Setting',
  AssetSubmissionDate: 'Submission Date'
};

const PaginationLimit = 8;


const AssetSuggestion = ({ suggestion, onClickSuggestion }) => (
  suggestion ? <div className="asset-suggestion" > Did you mean: <a onClick={() => { onClickSuggestion(suggestion) }} >{suggestion}</a>?</div> : null
);

const AssetOverlay = ({ error, onReload }) => (
  <div className="asset-loading-overlay" >
    { error ? <span>There was an error loading those assets.<br/>Please <a onClick={onReload} >click here</a> to try again.</span> : <span>Loading results …</span> }
  </div>
);

const IndexView = ({ initialized, loading, error, suggestion, search, query, anchorRef, filterSelectorRef, categories, category, subsets, subset, filtersAvailable, filtersSelected, view, assets, page, pagination, onClickReload, onSearchChange, onQueryChange, onFilterChoice, onFilterDeselect, onFilterValueChoice, onClearFilters, onToggleView, onTypeSelect, onTagSelect, onTagUnselect, onPagePrev, onPageNext, onPageSelect }) => {
  return (
    <div className="assets-search" >
      <section className="condensed-width-content">
        <div className="columns ratio-1-1">
          <div className="col">
            <div className="content-block">
              <div className="bc-assets-search" >
                <h2 className="h3">Search Brand Center</h2>
                <form data-track="search-bar" data-track-search-term={ search } onSubmit={ (event) => { event.preventDefault(); onQueryChange(search); } } >
                  <input type="search" name="q" id="q" value={ search } placeholder="Enter keywords here to begin" onChange={onSearchChange} />
                  <button type="submit" data-track="search-bar"><span aria-hidden="true" className="fa fa-search fa-md"></span></button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="results" >
        <section className="condensed-width-content">
          <div id="asset-results" className={(pagination.totalCount == 0) ? 'asset-results-empty' : ''} >
            <AssetSuggestion suggestion={suggestion} onClickSuggestion={onQueryChange} />
            <div ref={anchorRef} className="asset-filter asset-filter-categories" >
              <FilterCategoryList categories={categories} selected={category} onSelect={onTypeSelect} />
              <div className="asset-filter-options" >
                { Object.keys(filtersAvailable).length ? <AssetDropdown title="Filters" ref={filterSelectorRef} choices={Object.keys(filtersAvailable)} labels={FilterLabels} multiselect={true} onChoice={onFilterChoice} /> : '' }
                {
                  assets.length ?
                    <AssetViewToggle onToggle={onToggleView} view={(view == 'grid' ? 'list' : 'grid')} />
                  : ''
                }
              </div>
            </div>
            {
              filtersSelected.length ?
                <div className="asset-filter" >
                  {
                    filtersSelected.map((filter, index) => (
                      <div key={filter} className="asset-filter-container">
                        <div className="asset-filter-remove" onClick={() => { onFilterDeselect(filter) }} data-track="remove-filter" data-track-filter-name="Dropdown Filter" data-track-filter-option={FilterLabels[filter] ? FilterLabels[filter] : filter} ></div>
                        <AssetDropdown title={FilterLabels[filter] ? FilterLabels[filter] : filter} choices={filtersAvailable[filter]} multiselect={false} onChoice={(selection) => { onFilterValueChoice(filter, selection); }} />
                      </div>
                    ))
                  }
                  <div className="asset-filters-clear" onClick={onClearFilters} data-track="reset" >
                    Clear Filters
                  </div>
                </div>
              : ''
            }
            {
              Object.keys(subsets).length ?
                <div className={`asset-filter asset-filter-subsets${(subset == '') ? '' : ' subset-selected'}`} >
                  <FilterSubsetList subsets={subsets} selected={subset} onSelect={onTagSelect} onUnselect={onTagUnselect} />
                </div>
              : ''
            }
            {
              initialized && !error ?
              <div>
                <div className={`asset-hide-empty asset-results-area ${ loading ? ' loading' : '' }`} >
                  <AssetOverlay error={error} onReload={onClickReload} />
                  <div className="asset-results-info" >
                    Results <b><span>{pagination.range.from}</span> - <span>{pagination.range.to}</span></b> of <b>{pagination.totalCount}</b> for <b>{query}</b>
                    <div className="asset-top-pagination" >
                      <AssetPageControl onPrev={onPagePrev} onNext={onPageNext} onSelect={onPageSelect} limit={PaginationLimit} current={page} pagination={pagination} bottom={false} />
                    </div>
                  </div>
                  <div className="asset-results-container" >
                    <div className={`asset-results asset-results-${view}`} >
                      <AssetList query={query} assets={assets} />
                    </div>
                  </div>
                  <div className="asset-bottom-pagination" >
                    <AssetPageControl onPrev={onPagePrev} onNext={onPageNext} onSelect={onPageSelect} limit={PaginationLimit} current={page} pagination={pagination} bottom={true} />
                  </div>
                </div>
                <div className="asset-show-empty" >
                  <div className="asset-results-info" >
                    <b>0</b> results for &rsquo;<b>{query}</b>&rsquo;<br />
                    Sorry, we couldn’t find any content for &rsquo;<b>{query}</b>&rsquo;<br />
                    <h6>Search Tips:</h6>
                    <ul>
                      <li>Check your spelling.</li>
                      <li>Broaden your search by using fewer or more general words.</li>
                    </ul>
                    <h6>Need assistance?</h6><br />
                    <a href="/corporate/brand-center/contact" className="oc-cta-button" data-track="learn-more" data-track-destination="Contact Us" data-track-professional="owens-corning" >Contact Us</a>
                  </div>
                </div>
              </div>
              :
              <div className="asset-results-area loading initializing" >
                <AssetOverlay error={error} onReload={onClickReload} />
              </div>
            }
          </div>
        </section>
      </div>
    </div>
  );
};


class IndexController extends React.Component {
  constructor(props) {
    super(props);

    this.requestController = null;
    this.subset = "";

    this.state = {
      height: 0,
      initialized: false,
      loading: false,
      error: false,
      suggestion: false,
      search: props.query,
      query: props.query,
      categories: [AssetCategories[0]],
      category: "all",
      subsets: {},
      subset: "",
      filtersAvailable: {},
      filtersSelected: [],
      filterValues: {},
      view: "grid",
      assets: [],
      page: 1,
      pagination: {
        perPage: 30,
        totalCount: 1,
        pageCount: 0,
        range: {
          from: 0,
          to: 0
        }
      }
    };

    this.anchorRef = React.createRef();
    this.filterSelectorRef = React.createRef();

    this.loadAssets = this.loadAssets.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);

    this.onFilterChoice = this.onFilterChoice.bind(this);
    this.onFilterDeselect = this.onFilterDeselect.bind(this);
    this.onFilterValueChoice = this.onFilterValueChoice.bind(this);
    this.onClearFilters = this.onClearFilters.bind(this);

    this.onToggleView = this.onToggleView.bind(this);

    this.onTypeSelect = this.onTypeSelect.bind(this);
    this.onTagSelect = this.onTagSelect.bind(this);
    this.onTagUnselect = this.onTagUnselect.bind(this);

    this.onPagePrev = this.onPagePrev.bind(this);
    this.onPageNext = this.onPageNext.bind(this);
    this.onPageSelect = this.onPageSelect.bind(this);
  }

  componentDidMount() {
    this.loadAssets();
    this.props.history.listen((location, action) => {
      if (action === "PUSH") return;
      let query = Query.parse(location.search, {ignoreQueryPrefix: true})['q'];
      if (query != this.state.query) this.onQueryChange(query);
    });
  }


  // Asset loading and pagination

  loadAssets() {
    this.setState({ loading: true, error: false });
    if (this.filterSelectorRef.current) this.filterSelectorRef.current.setSelected(this.state.filtersSelected);

    let query = { height: this.state.height, query: this.state.query ? this.state.query : 'all', category: this.state.category, subset: this.subset, filters: JSON.stringify(this.state.filterValues), page: this.state.page };

    if (this.requestController) this.requestController.abort();
    this.requestController = new AbortController();

    fetch(`./assets?${ Query.stringify(query) }`, { signal: this.requestController.signal })
      .then(response => {
        this.setState({ subset: this.subset });

        if (response.status >= 200 && response.status < 300) {
          return response.json().then(reply => {
            const meta = reply.meta;
            this.setState({
              height: meta.height,
              initialized: true,
              loading: false,
              suggestion: meta.suggestion ? meta.suggestion : false,
              categories: AssetCategories.filter((category) => ( category.id == 'all' || meta.categories.includes(category.id) )),
              filtersAvailable: meta.height > this.state.height ? meta.filters : this.state.filtersAvailable,
              subsets: meta.subsets,
              assets: reply.data,
              pagination: {
                perPage: meta.limit,
                totalCount: meta.total,
                pageCount: Math.floor(meta.total / meta.limit),
                range: {
                  from: ((this.state.page - 1) * meta.limit) + 1,
                  to: Math.min((this.state.page) * meta.limit, meta.total)
                }
              }
            }, this.updateAnalytics);
          });
        } else {
          console.error("[BRAND CENTER] Error loading assets:", response);
          this.setState({ error: true });
        }
      }).catch(exception => {
        if (exception.name !== 'AbortError') {
          console.error('[BRAND CENTER] Error loading assets:', exception);
          this.setState({ error: true });
        }
      });
  }


  // Keep analytics digitalData object up to date with state after async and fire satellite track

  updateAnalytics() {
    const { pagination: { totalCount, pageCount, range: { from, to } }, category, subset, filterValues, page } = this.state;
    digitalData.page = {
      ...digitalData.page,
      pageType: totalCount ? 'Search Results Page' : 'Search Results Page - No Results',
      category,
      subset,
      filterValues,
      resultsCount: totalCount,
      resultsRange: `${from} - ${to}`,
      currentPage: page,
      pagesCount: pageCount + 1,
    };
    if(typeof(_satellite) !== "undefined")
      _satellite.track("Brand Center Search Request");
  }


  // Event handler for onChange of search bar

  onSearchChange(event) {
    this.setState({ search: event.target.value });
  }


  // Event handler for query change on search bar

  onQueryChange(query) {
    if (query === '') query = 'all';
    if (query != this.state.query) this.props.history.push(`results?${ Query.stringify({ q: query }) }`);
    this.subset = "";

    this.setState({
      search: query,
      query: query,
      initialized: false,
      category: "all",
      categories: [AssetCategories[0]],
      subset: "",
      subsets: {},
      filtersAvailable: {},
      filtersSelected: [],
      filterValues: {},
      assets: [],
      page: 1,
      height: 0
    }, this.loadAssets);

    if(this.filterSelectorRef.current) this.filterSelectorRef.current.clearSelection();
  }


  // Event handlers for selection of filter types and values

  onFilterChoice(selection) {
    let values = {};
    Object.keys(this.state.filterValues).forEach((filter) => {
      if (selection.includes(filter)) values[filter] = this.state.filterValues[filter];
    });
    this.setState({ filtersSelected: selection, filterValues: values }, this.loadAssets);
  }

  onFilterDeselect(filter) {
    let { [filter]: deselectedValue, ...newValues } = this.state.filterValues;
    this.subset = "";
    this.setState({
      filtersSelected: this.state.filtersSelected.filter(item => item !== filter),
      filterValues: newValues
    }, this.loadAssets);
  }

  onFilterValueChoice(filter, selection) {
    this.subset = "";
    this.setState({
      filterValues: {
        ...this.state.filterValues,
        ...{ [filter]: (selection[0] ? selection[0] : false) }
      },
      page: 1
    }, this.loadAssets);
  }

  onClearFilters() {
    this.subset = "";
    this.setState({ filtersSelected: [], filterValues: {}, page: 1 }, this.loadAssets);
    this.filterSelectorRef.current.clearSelection();
  }


  // Event handler for toggling view category

  onToggleView() {
    this.setState({ view: (this.state.view == "grid" ? "list" : "grid") });
  }


  // Scroll window back to top of search

  scrollToTop() {
    window.scrollTo(0, this.anchorRef.current.offsetTop + 40);
  }


  // Event handlers for filters and categories

  onTypeSelect(e, category) {
    this.subset = "";
    this.setState({ category, page: 1 }, this.loadAssets);
  }

  onTagSelect(e, subset) {
    this.subset = subset;
    this.setState({ page: 1 }, this.loadAssets);
  }

  onTagUnselect() {
    this.subset = "";
    this.setState({ page: 1 }, this.loadAssets);
  }


  // Event handlers for pagination

  onPagePrev(bottom) {
    this.setState({ page: Math.max(1, this.state.page - 1) }, this.loadAssets);
    if (bottom) this.scrollToTop();
  }

  onPageNext(bottom) {
    this.setState({ page: Math.min(this.state.pagination.pageCount + 1, this.state.page + 1) }, this.loadAssets);
    if (bottom) this.scrollToTop();
  }

  onPageSelect(e, page, bottom) {
    this.setState({ page: page }, this.loadAssets);
    if (bottom) this.scrollToTop();
  }


  render() {
    return (
      <IndexView
        {...this.state}
        anchorRef={this.anchorRef}
        filterSelectorRef={this.filterSelectorRef}
        onClickReload={this.loadAssets}
        onSearchChange={this.onSearchChange}
        onQueryChange={this.onQueryChange}
        onFilterChoice={this.onFilterChoice}
        onFilterDeselect={this.onFilterDeselect}
        onFilterValueChoice={this.onFilterValueChoice}
        onClearFilters={this.onClearFilters}
        onToggleView={this.onToggleView}
        onTypeSelect={this.onTypeSelect}
        onTagSelect={this.onTagSelect}
        onTagUnselect={this.onTagUnselect}
        onPagePrev={this.onPagePrev}
        onPageNext={this.onPageNext}
        onPageSelect={this.onPageSelect}
      />
    );
  }
}

const WrappedIndex = withRouter(IndexController);

const App = (props) => (
  <BrowserRouter>
    <WrappedIndex {...props} />
  </BrowserRouter>
);

export default App;
