import { useState } from "react";
import styled from "@emotion/styled";
import _ from "lodash";

// components
import FormButton from '../../../ComponentLibrary/input-elements/oc-form-button';
import TextInput from '../../../ComponentLibrary/input-elements/oc-text-input';

// styled components
const FormSection = styled.div`
  display: inline-block;
  margin-bottom: 32px;
  position: relative;
  width: 100%;

  &.split {
    display: flex;
    flex-wrap: wrap;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
  position: relative;

  button {
    margin-right: 1em;
  }
`;

const QuoteDetailsForm = ({ quoteDetail, isNew, closeModal, saveQuoteDetail }) => {
  const [modalQuoteDetail, setModalQuoteDetail] = useState(quoteDetail);

  const modalChangeQuoteDetail = (event, field, value = null) => {
    let changedQuoteDetail = _.cloneDeep(modalQuoteDetail);
    let newValue = value ?? event.target.value;
    changedQuoteDetail[field] = newValue;

    setModalQuoteDetail(changedQuoteDetail);
  };

  const modalTitle = () => {
    if (isNew)
      return "ADD NEW DETAIL SECTION";

    return "EDIT DETAIL SECTION";
  }

  return (
    <>
      <h3 uppercase="true" mt="14">{modalTitle()}</h3>
      <form onSubmit={(event) => saveQuoteDetail(event, modalQuoteDetail)}>
        <FormSection>
          <TextInput
            label="Title"
            name="quote-detail-title"
            value={modalQuoteDetail.title}
            onChange={(event) => modalChangeQuoteDetail(event, "title")}
            required={true}
          />
        </FormSection>
        <FormSection>
          <TextInput
            label="Description"
            name="quote-detail-description"
            onChange={(event) => modalChangeQuoteDetail(event, "description")}
            placeholder="Write a description"
            multiline
            rows={4}
            value={modalQuoteDetail.description}
            required={true}
          />
        </FormSection>
        <ModalFooter>
          <FormButton type="submit">
            {`${isNew ? "Add" : "Save"}`}
          </FormButton>
          <FormButton
            onClick={() => closeModal()}
            background="white"
            color="#FFFFFF"
          >
          Cancel
          </FormButton>
        </ModalFooter>
      </form>
    </>
  )

};
export default QuoteDetailsForm;
