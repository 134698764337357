import React from "react";

const FormatOptionLabels = {
  '.jpg': 'JPG - Solid Background',
  '.png': 'PNG - Transparent Background',
  '.eps': 'EPS - Scalable vector file'
};

export const ApplicationSelector = ({ selected, options, onChange }) => {
  if (Object.keys(options).length <= 1) return null;

  return (
    <div className="asset-application-select">
      <div className="asset-application-select-title" >SELECT APPLICATION:</div>
      <select value={selected} onChange={((e) => onChange(e))} >
        {Object.keys(options).map((key) => {
          return <option key={key} value={options[key].identifier} >{options[key].application}</option>
        })}
      </select>
    </div>
  );
};

export const FormatSelector = ({ selected, formats, onChange }) => {
  if (Object.keys(formats).length <= 1) return null;

  return (
    <div className="asset-application-select">
      <div className="asset-application-select-title" >SELECT FORMAT:</div>
      <select value={selected} onChange={((e) => onChange(e))} >
        {Object.keys(formats).map((key) => {
          return <option key={key} value={key} >{FormatOptionLabels[key]}</option>
        })}
      </select>
    </div>
  );
};
