const formatMoney = (num, decimals) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: (decimals ? 2 : 0),
  }).format((decimals) ? num : num.toFixed(0));
};

const totalContingenciesPrice = (contingencies) => {
  return contingencies?.reduce(
    (totalPrice, contingency) => totalPrice + parseFloat(contingency.cost),
    0
  ) || 0;
};

const maxPriceValidator = (values, maxPrice) => {
  const { floatValue } = values;
  return floatValue <= maxPrice || floatValue === undefined;
};

const markupValidator = (values) => {
  const { floatValue } = values;
  return (floatValue >= 0 && floatValue <= 100) || floatValue === undefined;
};

const minPitchFactor = (values) => {
  const { floatValue } = values;
  return floatValue >= 1 || floatValue === undefined;
};

const presentMoneyRange = (min, max, decimals = true) => {
  if (min === max)
    return formatMoney(min, decimals);

  return `${formatMoney(min, decimals)} - ${formatMoney(max, decimals)}`;
};

export { formatMoney, totalContingenciesPrice, maxPriceValidator, markupValidator, minPitchFactor, presentMoneyRange };
