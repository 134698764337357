import React from "react";

const DownloadOption = ({ asset, id, selected, onSelect, title, description, size, dpi, color, fps }) => {
  return (
    <div
      className={`asset-download-option${selected == id ? " asset-download-option-selected" : ""}`}
      onClick={((e) => onSelect(e, id))}
      data-track="select-asset"
      data-track-object-id={asset.identifier}
      data-track-object={asset.title}
    >
      <div className="asset-download-choose-option"></div>
      <div className="asset-download-title">{title}</div>
      <div className="asset-download-description">{description}</div>
      <div className="asset-download-sizing">
        <div className="asset-sizing-initial">{size}</div>
        <div className="asset-sizing-sub">
          {`${dpi ? dpi + " dpi - " : ""}${color ? color : ''}${fps ? fps + " fps" : ''}`}
        </div>
      </div>
    </div>
  );
};

export const DownloadOptionList = ({ options, ...props }) => options.map((o, i) => <DownloadOption key={i} id={i} {...props} {...o} />);
