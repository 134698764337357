import {jsx, css} from '@emotion/react';
import { useState } from 'react';
import CTAButton from "./CTAButton";
import Splash from "./Splash";
import Modal from "./Modal";
import {store} from './Store'
import { Provider } from "react-redux";


const basicFormStyles = css`
`

const buttonContainer = css`
  margin: 0 auto;
`

const HVACSplash = () => {
  const [modalOpen, setModal] = useState(false);

  return (
    <Provider store={store}>
      <Splash>
        <h3>CONTRACTORS: FIND THE OPPORTUNITIES YOU ARE MISSING</h3>
        <p>Join our mailing list to get business-building ideas delivered straight to your inbox.</p>
        <div css={buttonContainer}>
          <CTAButton href="https://owenscorning.powerappsportals.com/retrofit-subscribe/" text="Subscribe" />
        </div>
      </Splash>
    </Provider>
  );
}

export default HVACSplash;
