import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import _ from "lodash";

// Components
import AlertMessage from '../../../OC/oc-alert-message';
import Label from "../../../ComponentLibrary/input-elements/oc-label";
import NumberFormat from "react-number-format";

// Functions
import { maxPriceValidator, minPitchFactor, markupValidator } from "../../QuotingWidget/functions/sharedFunctions";

// Styled Components
const TableHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid #959595;
  display: flex;
  position: relative;

  h6 {
    flex-grow: 1;
    margin: 32px 0px 4px 0px;
  }

  span {
    flex-shrink: 0;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    width: 64px;
  }
`;

const Table = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 24px 28px;
  width: 100%;

  &:nth-of-type(odd) {
    background-color: #F8F8F8;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

const Data = styled.div`
  display: inline-block;
  flex-shrink: 0;
  padding-left: ${props => props.paddingLeft || "12px"};
  padding-right: 12px;
  position: relative;
  width: ${props => props.width || "25%"};

  @media screen and (max-width: 767px) {
    margin-bottom: 1em;
    width: 100%;
    justify-content: center;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.markup-value {
    color: #6D6D6D;

    strong {
      display: inline-block;
      margin-bottom: 12px;
      width: 100%;
    }

    span {
      display: inline-block;
      padding: 14px 8px;
    }
  }

  h4 {
    margin-bottom: 0;
  }
`;

const Descriptor = styled.span`
  font-size: 12px;
  line-height: 17px;
`;

const Input = styled(NumberFormat)`
  appearance: none;
  border-radius: 0;
  border: 1px solid #585858;
  display: inline-block;
  font-size: 16px;
  margin-bottom: ${props => props.marginBottom || 0};
  padding: 14px 8px;
  position: relative;
  width: 100%;

  &:focus {
    border-color: #d40f7d;
    outline: none;
  }
`;

const PricingBreakdown = ({ configuration, updateData, onPricingWarningChange, showPricingWarning }) => {
  const fields = ['simple-pitch-factor', 'simple-price', 'moderate-pitch-factor', 'moderate-price', 'complex-pitch-factor', 'complex-price'];
  const checkEmptyFields = () => fields.some(field => !configuration.data[field])
  const [isEmpty, setIsEmpty]  = useState(checkEmptyFields)

  const updatePricing = (event, field) => {
    const value = (event.floatValue) ? event.floatValue : 0;
    updateData(null, field, value);
  };

  useEffect(() => {
    setIsEmpty(checkEmptyFields)
    onPricingWarningChange(isEmpty);
  }, [configuration, onPricingWarningChange, isEmpty]);

  return (
    <>
      {showPricingWarning &&
        <AlertMessage
          title="Enter Pricing Values"
          message='All values in the customized price settings must be entered before publishing a widget to your website.'
          type='warning'
          alertIcon
        />
      }

      <TableHeader>
        <h6>Customized Price Settings</h6>
      </TableHeader>
      <Table>
        <Row>
          <Data>
            <h4>Simple Roof</h4>
            <Descriptor>Least complex option</Descriptor>
          </Data>
          <Data width="25%">
            <Label
              text="Pitch/Overhang Factor"
              required={true}
            />
            <Input
              allowEmptyFormatting={false}
              allowNegative={false}
              decimalScale={2}
              isAllowed={(values) => minPitchFactor(values)}
              onValueChange={(event) => updatePricing(event, "simple-pitch-factor")}
              thousandSeparator={false}
              value={configuration.data["simple-pitch-factor"] || "" }
              required={true}
            />
          </Data>
          <Data width="25%">
            <Label
              text="Price per Sq. Ft. of Home"
              required={true}
            />
            <Input
              allowEmptyFormatting={true}
              allowNegative={false}
              decimalScale={2}
              isAllowed={(values) => maxPriceValidator(values, 999)}
              onValueChange={(event) => updatePricing(event, "simple-price")}
              prefix="$"
              thousandSeparator={true}
              value={configuration.data["simple-price"] || "" }
              required={true}
            />
          </Data>
          <Data width="25%">
            <Label
              text="Percent Range Markup"
              helperText="Optional"
              required={false}
            />
            <Input
              isAllowed={(values) => markupValidator(values)}
              allowEmptyFormatting={true}
              allowNegative={false}
              decimalScale={0}
              onValueChange={(event) => updatePricing(event, "simple-markup")}
              thousandSeparator={true}
              suffix="%"
              value={configuration.data["simple-markup"] || "" }
              required={false}
            />
          </Data>
        </Row>
        <Row>
          <Data>
            <h4>Moderate Roof</h4>
            <Descriptor>Roof with 1-2 pitches, easier access</Descriptor>
          </Data>
          <Data width="25%">
            <Label text="Pitch/Overhang Factor" required={true} />
            <Input
              allowEmptyFormatting={true}
              allowNegative={false}
              decimalScale={2}
              isAllowed={(values) => minPitchFactor(values)}
              onValueChange={(event) => updatePricing(event, "moderate-pitch-factor")}
              thousandSeparator={true}
              value={configuration.data["moderate-pitch-factor"] || "" }
              required={true}
            />
          </Data>
          <Data width="25%">
            <Label text="Price per Sq. Ft. of Home" required={true} />
            <Input
              isAllowed={(values) => maxPriceValidator(values, 999)}
              allowEmptyFormatting={true}
              allowNegative={false}
              decimalScale={2}
              onValueChange={(event) => updatePricing(event, "moderate-price")}
              prefix="$"
              thousandSeparator={true}
              value={configuration.data["moderate-price"] || "" }
              required={true}
            />
          </Data>
          <Data width="25%">
            <Label text="Percent Range Markup" required={false} helperText="Optional" />
            <Input
              isAllowed={(values) => markupValidator(values)}
              allowEmptyFormatting={true}
              allowNegative={false}
              decimalScale={0}
              onValueChange={(event) => updatePricing(event, "moderate-markup")}
              thousandSeparator={true}
              suffix="%"
              value={configuration.data["moderate-markup"] || "" }
              required={false}
              helperText="Optional"
            />
          </Data>
        </Row>
        <Row>
          <Data>
            <h4>Complex Roof</h4>
            <Descriptor>Over 3 types of pitches, etc.</Descriptor>
          </Data>
          <Data width="25%">
            <Label text="Pitch/Overhang Factor" required={true} />
            <Input
              allowEmptyFormatting={true}
              allowNegative={false}
              isAllowed={(values) => minPitchFactor(values)}
              decimalScale={2}
              onValueChange={(event) => updatePricing(event, "complex-pitch-factor")}
              thousandSeparator={true}
              value={configuration.data["complex-pitch-factor"] || "" }
              required={true}
            />
          </Data>
          <Data width="25%">
            <Label text="Price per Sq. Ft. of Home" required={true} />
            <Input
              allowEmptyFormatting={true}
              allowNegative={false}
              decimalScale={2}
              isAllowed={(values) => maxPriceValidator(values, 999)}
              onValueChange={(event) => updatePricing(event, "complex-price")}
              prefix="$"
              thousandSeparator={true}
              value={configuration.data["complex-price"] || "" }
              required={true}
            />
          </Data>
          <Data width="25%">
            <Label text="Percent Range Markup" required={false} helperText={"Optional"}/>
            <Input
              isAllowed={(values) => markupValidator(values)}
              allowEmptyFormatting={true}
              allowNegative={false}
              decimalScale={0}
              onValueChange={(event) => updatePricing(event, "complex-markup")}
              suffix="%"
              thousandSeparator={true}
              value={configuration.data["complex-markup"] || "" }
              required={false}
            />
          </Data>
        </Row>
      </Table>
    </>
  );
}

export default PricingBreakdown;
