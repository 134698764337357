import { DataTrack, If } from "../../../Utilities";
import styled from "@emotion/styled";
import _ from "lodash";

// Components
import TextInput from "../../../ComponentLibrary/input-elements/oc-text-input";
import FormButton from "../../../ComponentLibrary/input-elements/oc-form-button";

// Styled Components
const FormSection = styled.div`
  display: inline-block;
  margin-bottom: 32px;
  position: relative;
  width: 100%;
`;

const H4 = styled.h4`
  text-transform: ${props => props.uppercase ? "uppercase" : "none"};
`;

const RadioLabel = styled.label`
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 16px;
  position: relative;
  width: 100%;

  input[type="radio"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #D1D1D1;
    flex-shrink: 0;
    height: 16px;
    margin: 1px 8px 0 0;
    width: 16px;

    & ~ .radio {
      background: #D40F7D;
      border-radius: 6px;
      display: none;
      height: 8px;
      left: 4px;
      position: absolute;
      top: 5px;
      width: 8px;
    }

    &:checked {
      background-color: white;
      border-color: #D40F7D;

      & ~ .radio {
        display: inline-block;
      }
    }
  }
`;

const PseudoTable = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const PseudoTableRow = styled.div`
  border-bottom: 1px solid;
  border-color: #BCBCBC;
  display: flex;
  justify-content: flex-start;
  position: relative;

  &:nth-of-type(even) {
    background-color: #F8F8F8;
  }
`;

const PseudoTableHeader = styled(PseudoTableRow)`
  border-bottom: 2px solid;
  border-top: 2px solid;

  > div {
    font-size: 16px;
    font-weight: bold;
  }
`;

const PesudoCell = styled.div`
  align-items: center;
  border-right: 1px solid #BCBCBC;
  display: inline-flex;
  font-size: 14px;
  justify-content: center;
  padding: 8px 10px;
  position: relative;
  width: calc(100% / 4);

  &:first-of-type {
    justify-content: flex-start;
  }

  &:last-of-type {
    border-right: none;
  }

  &.action-container {
    justify-content: space-evenly;
  }

  @media screen and (max-width: 767px) {
    &:first-of-type {
      width: 50%;
    }

    &:nth-of-type(2) {
      display: none;
    }
  }
`;

const ActionLink = styled.a`
  cursor: pointer;
  display: inline-block;
  position: relative;
`;

const CreateOrEdit = ({
  configurations,
  activeConfiguration,
  setActiveConfiguration,
  newConfiguration,
  displayEditTable,
  setDisplayEditTable,
  setIsEditing,
  updateData,
  saveConfiguration,
  saving
}) => {
  const selectNew = () => {
    let newConfig = _.cloneDeep(newConfiguration)
    newConfig.data["configurationName"] = `Configuration ${configurations.length + 1}`
    setActiveConfiguration(newConfig);
    setDisplayEditTable(false);
  };

  const selectEdit = () => {
    let firstConfiguration = _.cloneDeep(configurations[0]);
    setActiveConfiguration(firstConfiguration);
    setDisplayEditTable(true);
  };

  const editConfig = (configId) => {
    let configuration = _.find(configurations, { 'id': configId });
    setActiveConfiguration(_.cloneDeep(configuration));
    setIsEditing(true);
    window.scrollTo({
      behavior: "smooth",
      top: 0
    });
  };


  const filterNewerConfigurationsByDate = (configurations) => {
    const OLDER_CONFIGURATION_DATE_THRESHOLD = new Date('2024-03-01');
    return configurations.filter(config => new Date(config.updated_at) > OLDER_CONFIGURATION_DATE_THRESHOLD);
  }

  return (
    <>
      <FormSection>
        <H4 className="body-font" uppercase>How Would You Like to Begin?</H4>
        <RadioLabel htmlFor="new">
          <DataTrack track="toggle" option="Create New">
            <input id="new" type="radio" onChange={selectNew} checked={!displayEditTable} className="radio" />
          </DataTrack>
          Create New Configuration
          <span
            className="radio"
            role="checkbox"
          />
        </RadioLabel>

        <If condition={configurations.length > 0}>
          <RadioLabel htmlFor="edit">
            <DataTrack track="toggle" option="Edit Existing">
              <input id="edit" type="radio" onChange={selectEdit} checked={displayEditTable} className="radio" />
            </DataTrack>
            Edit Existing
            <span
              className="radio"
              role="checkbox"
            />
          </RadioLabel>
        </If>
      </FormSection>

      <If condition={!displayEditTable}>
        <FormSection>
          <H4>Name of New Configuration</H4>
          <TextInput
            onChange={(event) => updateData(event, "configurationName")}
            value={activeConfiguration?.data["configurationName"]}
          />
        </FormSection>

        <FormSection>
          <FormButton disabled={saving} onClick={() => saveConfiguration()}>
            Create New Configuration
          </FormButton>
        </FormSection>
      </If>

      <If condition={displayEditTable}>
        <FormSection>
          <H4 className="body-font" uppercase>List of Existing Configurations</H4>
          <p>Select the existing configuration below to edit or preview.</p>

          <PseudoTable>
            <PseudoTableHeader>
              <PesudoCell>Name of Configuration</PesudoCell>
              <PesudoCell>Date Created</PesudoCell>
              <PesudoCell>Last Updated</PesudoCell>
              <PesudoCell>Action</PesudoCell>
            </PseudoTableHeader>
            {filterNewerConfigurationsByDate(configurations).map(configuration => (
              <PseudoTableRow key={configuration.id}>
                <PesudoCell>{configuration.data["configurationName"]}</PesudoCell>
                <PesudoCell>{new Date(configuration.created_at).toLocaleDateString()}</PesudoCell>
                <PesudoCell>{new Date(configuration.updated_at).toLocaleDateString()}</PesudoCell>
                <PesudoCell className="action-container">
                  <ActionLink onClick={() => editConfig(configuration.id)}>Edit</ActionLink>
                  {/* <ActionLink onClick={}>Preview</ActionLink> */}
                </PesudoCell>
              </PseudoTableRow>
            ))}
          </PseudoTable>
        </FormSection>
      </If>
    </>
  );
}

export default CreateOrEdit;
