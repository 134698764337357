import { jsx, css } from '@emotion/react';

const splashCSS = css`
  align-items: center;
  background: black;
  color: white;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 35px;
  padding: 30px;

  @media (min-width: 768px) {
    display: flex;
  }

  &.no-margin-bottom, p {
    margin-bottom: 0;
  }

  h3, h4, h5 {
    color: white;
    margin-bottom: 0;
  }
`

const textContainer = css`
  @media (min-width: 1200px) {
    width: 60%;
  }

  @media (min-width: 1200px) {
    width: 65%;
  }
`

const Splash = ({ children, className }) => (
  <div className={className} css={splashCSS}>
    <div css={textContainer}>
      <div>{children[0]}</div>
      <div>{children[1]}</div>
    </div>
    <div>{children[2]}</div>
  </div>
)

export default Splash;
