import { useRef, useEffect, useState } from "react";

import Cta from './oc-cta.jsx';
import Image from './oc-image';

import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { connectLocation } from '../location/LocationConnectors';
import Conditional from '../Conditional';
import { Provider } from 'react-redux';
import { store } from '../Store';
import { useInView } from "react-intersection-observer";
import anime from "animejs";
import AnimateTextContainer from "./shared/oc-animated-text-container";
import AnimatedAttentionBarWrapper from "./shared/oc-animate-wrapper";

const AttentionBarWithTimerContainer = styled.div`
  background: black;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 auto;

  @media (min-width: 768px) {
    flex-direction: row;
    ${props => props.width === 'page' && css`
      max-width: 768px;
    `};

    ${props => props.width === 'full' && css`
      width: 100%;
    `};
  }

  @media (min-width: 1200px) {
    ${props => props.width === 'page' && css`
      max-width: 1170px;
    `};

    ${props => props.width === 'full' && css`
      width: 100%;
    `};
  }
`;

const Media = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;

  @media (min-width: 768px) {
    background: url(https://imagecdn.owenscorning.com/ocimages/image/upload/v1631211163/countdown-timer/timer-bg.png) no-repeat right center;
    flex-basis: 40%;
    padding: 24px 16px;
  }

  @media (min-width: 1200px) {
    flex-basis: 32%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
`;

const TextContent = styled.div`
  padding: 24px;
  color: white;

  @media (min-width: 768px) {
    flex-basis: 60%;
  }

  @media (min-width: 1200px) {
    flex-basis: 68%;
  }

  h2, h3 {
    color: white!important;
  }
`;

const Timer = styled.div`
  border: 3px solid white;
  padding: 8px 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TimerSection = styled.div`
  font-family: 'Oswald', sans-serif;
  text-align: center;
  font-size: 55px;
  line-height: 55px;

  @media (min-width: 768px) {
    font-size: 45px;
    line-height: 45px;
  }

  @media (min-width: 1200px) {
    font-size: 67px;
    line-height: 67px;
  }
`;

const TimerDivider = styled.div`
  font-family: 'Oswald', sans-serif;
  font-size: 55px;
  line-height: 55px;
  margin: 0 4px 30px;

  @media (min-width: 768px) {
    font-size: 45px;
    line-height: 43px;
    margin: 0 4px 30px;
  }

  @media (min-width: 1200px) {
    font-size: 67px;
    line-height: 67px;
    margin: 0 8px 24px;
  }
`;

const TimerLabel = styled.span`
  font-family: 'OCRoboto', sans-serif;
  text-transform: uppercase;
  display: block;
  margin-top: 8px;
  font-size: 15px;
  line-height: 15px;

  @media (min-width: 768px) {
    font-size: 13px;
    line-height: 13px;
  }

  @media (min-width: 1200px) {
    font-size: 15px;
    line-height: 15px;
  }
`;

const AttentionBarWithTimer = connectLocation(({ roofing_psa, afterImg, alt, date, prehead, afterPrehead, heading, afterHeading, width='page', dataRegion, animationProps}) => {
  const {animate, flashAnimRef, slideAnimRef } = animationProps || {animate: false, flashAnimRef: useRef(null), slideAnimRef: useRef(null)};
  const [timerDays, setTimerDays] = useState('00');
  const [timerHours, setTimerHours] = useState('00');
  const [timerMinutes, setTimerMinutes] = useState('00');
  const [timerSeconds, setTimerSeconds] = useState('00');
  const [dateDiff, setdateDiff] = useState('');

  let interval = useRef();

  const startTimer = () => {
    const countdownDate = new Date(date).getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const dateDifference = countdownDate - now;

      let days = Math.floor(dateDifference / (1000 * 60 * 60 * 24));
      let hours = Math.floor((dateDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((dateDifference % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((dateDifference % (1000 * 60)) / 1000);

      if (dateDifference < 0) {
        clearInterval(interval.current);
        setdateDiff(dateDifference);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
        setdateDiff(dateDifference);
      }

    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    }
  });

  const [inViewRef, inView] = useInView({
    threshold: 1,
    triggerOnce: true, // set this flag to false if you want to willAnimate the text on every scroll into view
  });

  const animationTimeline = anime.timeline({
    autoplay: false,
  });

  useEffect(() => {
    if (animate) {
      AnimateTextContainer(inView, slideAnimRef.current, animationTimeline);
    }
  }, [inView, animate]);

  return (
    <Conditional if={  roofing_psa === '1012' }>
      <AnimatedAttentionBarWrapper
        wrapperComponent={AttentionBarWithTimerContainer}
        wrapperProps={{
          width: width,
          'data-am-region': dataRegion,
          className: 'flash-bar-timer',
        }}
        target={flashAnimRef}
        animate={animate}
        reference={inViewRef}
        inView={inView}
        animationTimeline={animationTimeline}
      >
        <Media>
          {dateDiff < 0 ?
            <Image src={afterImg} />
            :
            <Timer>
              <TimerSection>
                {timerDays}
                <TimerLabel>Days</TimerLabel>
              </TimerSection>
              <TimerDivider>:</TimerDivider>
              <TimerSection>
                {timerHours}
                <TimerLabel>Hours</TimerLabel>
              </TimerSection>
              <TimerDivider>:</TimerDivider>
              <TimerSection>
                {timerMinutes}
                <TimerLabel>Minutes</TimerLabel>
              </TimerSection>
            </Timer>
          }
        </Media>

        <TextContent ref={slideAnimRef}>
          <h3 prehead={dateDiff < 0 ? afterPrehead : prehead} dangerouslySetInnerHTML={{ __html: dateDiff < 0 ? afterHeading : heading }}></h3>
        </TextContent>
      </AnimatedAttentionBarWrapper>
    </Conditional>
  )
})

export default (props) => (
  <Provider store={store}>
    <AttentionBarWithTimer {...props}/>
  </Provider>
)
