import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import _ from "lodash";

// Components
import Checkbox from "../../../ComponentLibrary/input-elements/oc-checkbox";
import FormModal from "../../../ComponentLibrary/forms/oc-form-modal";
import ContingencyForm from "./ContingencyForm";
import NumberFormat from "react-number-format";

// Styled Components
const ContingenciesHeader = styled.div`
  border-bottom: 1px solid #959595;
  display: flex;
  position: relative;

  h6 {
    flex-grow: 1;
    margin-bottom: 12px;
  }

  span {
    flex-shrink: 0;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
  }
`;

const ContingenciesContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const Contingency = styled.div`
  display: flex;
  padding: 24px;
  position: relative;

  &:nth-of-type(odd) {
    background-color: #F8F8F8;
  }
`;

const TextContainer = styled.div`
  display: inline-block;
  flex-grow: 1;
  position: relative;

  input, textarea {
    margin-bottom: 16px;
  }
`;

const CheckContainer = styled.div`
  align-items: flex-start;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: flex-end;
  width: 64px;

  label {
    input[type="checkbox"] {
      cursor: pointer;
      margin-right: 0;
    }
  }
`;

const EditLink = styled.a`
  color: ${props => props.deleting ? "#CE1126" : "black"};
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  line-height: 20px;

  &:not(:first-of-type) {
    margin-left: 24px;
  }
`;

const AddNew = styled.a`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-size: 18px;
  line-height: 22px;
  padding: 16px 0;
  text-decoration: none;

  span {
    display: inline-block;
    margin-left: 16px;
  }
`;

const H4 = styled.h4`
  text-transform: ${props => props.uppercase ? "uppercase" : "none"};
`;

const Input = styled(NumberFormat, { displayType: "input" })`
  appearance: none;
  border-radius: 0;
  border: 1px solid #585858;
  display: inline-block;
  font-size: 16px;
  padding: 14px 8px;
  position: relative;
  width: 100%;
`;

const Contingencies = ({ configuration, updateData }) => {
  let configurationContingencies = configuration.data["contingencies"] || [];
  const [contingencies, setContingencies] = useState(configurationContingencies);
  const [displayFormModal, setDisplayFormModal] = useState(false);
  const [editingContingency, setEditingContingency] = useState({ isNew: true, contingency: null });

  const updateContingency = (editedContingency, index) => {
    let collection = _.cloneDeep(contingencies);
    collection[index] = editedContingency;

    setContingencies(collection);
    updateData(null, "contingencies", contingencies);
  };

  const toggleContingencyDisplay = (event, index) => {
    let collection = _.cloneDeep(contingencies);
    let value = event.target.checked;
    collection[index]["display"] = value;

    setContingencies(collection);
    updateData(event, "contingencies", collection);
  };

  const removeContingency = (removeIndex) => {
    let collection = _.cloneDeep(contingencies);
    let updatedCollection = _.reject(collection, (object, index) => { return index == removeIndex });
    setContingencies(updatedCollection);
    updateData(null, "contingencies", updatedCollection);
  };

  const openNewContingency = () => {
    setEditingContingency({
      isNew: true,
      contingency: {
        name: "",
        description: "",
        cost: "",
        display: true,
      },
    });
    setDisplayFormModal(true);
  };

  const editContingency = (index) => {
    setEditingContingency({
      isNew: false,
      contingencyIndex: index,
      contingency: contingencies[index],
    });
    setDisplayFormModal(true);
  }

  const saveContingency = (event, modalContingency) => {
    event.preventDefault();

    let updatedCollection = _.cloneDeep(contingencies)
    if (editingContingency.isNew) {
      updatedCollection = [...updatedCollection, modalContingency]
      setContingencies([...contingencies, modalContingency]);
    } else {
      updatedCollection[editingContingency.contingencyIndex] = modalContingency;
    };

    setContingencies(updatedCollection);
    updateData(null, "contingencies", updatedCollection)
    setDisplayFormModal(false);
  }

  const closeFormModal = () => {
    setDisplayFormModal(false);
  };

  useEffect(() => {
    let data = configuration.data["contingencies"]?.length ? configuration.data["contingencies"] : [];
    setContingencies(data);
  }, [configuration]);

  return (
    <>
      <ContingenciesHeader>
        <h6>Contingencies</h6>
        <span>Show in Quote</span>
      </ContingenciesHeader>
      <ContingenciesContainer>
        {contingencies.map((contingency, index) =>
          <Contingency key={`contingency-${index}-configuration-${configuration.id}`}>
            <TextContainer>
              <H4>{ contingency.name }</H4>
              <p>{ contingency.description }</p>
              <Input
                decimalScale={2}
                prefix="$"
                thousandSeparator={true}
                value={contingency.cost}
                disabled={true}
              />
              <EditLink onClick={() => editContingency(index)}>
                Edit
              </EditLink>
              <EditLink
                onClick={() => { if (window.confirm("Are you sure you want to delete this Contingency?")) removeContingency(index)}}
                deleting="true"
              >
                Delete
              </EditLink>
            </TextContainer>
            <CheckContainer>
              <Checkbox checked={contingency.display} onChange={(event) => toggleContingencyDisplay(event, index)} />
            </CheckContainer>
          </Contingency>
        )}

        <AddNew onClick={() => openNewContingency()}>
          Add New Contingency
          <span className="react-chevron-right"></span>
        </AddNew>
      </ContingenciesContainer>
      {displayFormModal &&
        <FormModal
          name="Quoting Form Modal"
          onClose={() => closeFormModal()}
          open={true}
        >
          <ContingencyForm
            contingency={editingContingency.contingency}
            isNew={editingContingency.isNew}
            closeModal={closeFormModal}
            saveContingency={saveContingency}
          />
        </FormModal>
      }
    </>
  );
}

export default Contingencies;
