import styled from "@emotion/styled";

const Container = styled.div`
  background-color: #DBDDF5;
  color: #060B6E;
  display: inline-block;
  font-size: 15px;
  line-height: 16px;
  padding: 12px 16px;
  position: relative;
  width: 100%;
`;

const InfoMessage = ({ message }) => {
  return (
    <Container>
      <strong>Info Message:</strong> {message}
    </Container>
  );
}

export default InfoMessage;
