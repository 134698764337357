import React from "react";
import fallback from "../../../../../assets/images/brand_center/FallbackImage.png";

import objectFitImages from 'object-fit-images';
objectFitImages();

const Thumbnail = ({ src, alt }) => (
  <div className="asset-preview-image" >
    <img src={ src } alt={ alt } onError={(e) => (e.currentTarget.src = fallback)}/>
  </div>
);

export default Thumbnail;
