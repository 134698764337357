import React from "react";
import { AssetDropdown } from './Dropdown';

const FilterCategory = ({ id, name, icon, selected, onSelect }) => (
	<div className={`asset-filter-item asset-filter-category${ selected == id ? ' selected' : '' }`} onClick={((e) => onSelect(e, id))} data-track="filter" data-track-filter-name="Asset Category" data-track-filter-option={name} >
		<i className={`fa fa-${icon}`} aria-hidden="true" ></i>
		<span>{name}</span>
	</div>
);

const FilterSubset = ({ name, label, count, selected, onSelect, onUnselect }) => (
	<div className={`asset-filter-item asset-filter-subset${ selected == name ? ' selected' : '' }`} >
		<div className="asset-filter-remove" onClick={onUnselect} data-track="remove-filter" data-track-filter-name="Asset Subset" data-track-filter-option={name} ></div>
		<div className="subset-content" onClick={((e) => onSelect(e, name))} data-track="filter" data-track-filter-name="Asset Subset" data-track-filter-option={name} >
			<span className="subset-name" >{label}</span>
			<span className="asset-filter-marker" >{count}</span>
		</div>
	</div>
);

const FilterChoiceSelect = ({ name, choices }) => (
  <div className="asset-filter-item" >
    <AssetDropdown title={title} choices={choices} />
  </div>
);

export const FilterCategoryList = ({ categories, selected, onSelect }) => categories.map(c => <FilterCategory key={c.name} {...c} selected={selected} onSelect={onSelect} />);
export const FilterSubsetList = ({ subsets, selected, onSelect, onUnselect }) => Object.keys(subsets).map(s => <FilterSubset key={s} name={s} label={s.split("-")[1].trim()} count={subsets[s]} selected={selected} onSelect={onSelect} onUnselect={onUnselect} />);
