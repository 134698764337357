import styled from '@emotion/styled';
import { css } from "@emotion/react";
import PropTypes from 'prop-types';

const SectionStyles = styled.section`
  margin: ${props => `${props.marginsTop[0]} auto ${props.marginsBottom[0]}`};
  max-width: ${props => props.fluidMobile ? 'none' : '400px'};

  @media screen and (min-width: 768px) {
    margin: ${props => `${props.marginsTop[1]} auto ${props.marginsBottom[1]}`};;
    max-width: ${props => (props.fluidTablet || props.fluid) ? 'none' : '768px'};
  }

  @media screen and (min-width: 1200px) {
    margin: ${props => `${props.marginsTop[2]} auto ${props.marginsBottom[2]}`};;
    max-width: ${props => props.fluid ? 'none' : '1170px'};
  }

  ${props => props.border && css`
    border-bottom: 2px solid black;
    padding-bottom: 20px;

    @media screen and (min-width: 768px) {
      padding-bottom: 30px;
    }

    @media screen and (min-width: 1200px) {
      padding-bottom: 70px;
    }

    &.mobile-no-border {
      @media(max-width: 767px) {
        border-bottom: none;
      }
    }
  `}
`;

const Section = ({ children, fluid=false, fluidTablet=false, fluidMobile=false, border=false, noMarginTop=false, noMarginBottom=false }) => {
  const defaultMargins = ['15px', '30px', '70px'];
  const marginsTop = noMarginTop ? ['0', '0', '0'] : defaultMargins;
  const marginsBottom = noMarginBottom ? ['10px', '10px', '10px'] : defaultMargins;

  return(
    <SectionStyles fluid={fluid} fluidTablet={fluidTablet} fluidMobile={fluidMobile} border={border} marginsTop={marginsTop} marginsBottom={marginsBottom} >
      {children}
    </SectionStyles>
  );
};

export default Section;


Section.propTypes = {
  /** This is where you put the content of the section */
  children: PropTypes.node,
  /** If you want an border at the bottom of the section */
  border: PropTypes.bool,
  /** If you want the section to be fluid only on mobile */
  fluidMobile: PropTypes.bool,
  /** If you want the section to be fluid only on tablet */
  fluidTablet: PropTypes.bool,
  /** If you want the section to be fluid only on tablet and desktop */
  fluid: PropTypes.bool,
  /** If you don't want top margin for a section */
  noMarginTop: PropTypes.bool
}
