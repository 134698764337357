import { Provider } from 'react-redux';

import { store } from '../../Store';
import { If } from '../../Utilities';
import { connectLocation } from '../../location/LocationConnectors';

const FindAProfessional = connectLocation(({ location }) => {
  // const [emailOpen, emailChangeState] = useState(false);
  // const emailOnClose = () => emailChangeState(false);
  // const emailModalElementFunc = () => document.getElementById('fac-email-me');
  return (
    <p>
      {/* For not SH wants to hide this email. Most likely we could re-use this code in future. */}
      {/*<If condition={location.source != 'ManualLocationSource'}>*/}
      {/*  <a*/}
      {/*    href="#fap-email-me"*/}
      {/*    data-track="open-modal"*/}
      {/*    data-track-modal-name="email-insulation-contractor"*/}
      {/*    data-toggle="modal"*/}
      {/*    data-track-contractor={`${location.name} - ${location.city}, ${location.state}`}*/}
      {/*    data-track-contractor-id={location.id}*/}
      {/*    data-contractor-name={location.name}*/}
      {/*    data-track-professional="insulation contractor"*/}
      {/*    data-contractor-city={location.city}*/}
      {/*    data-contractor-state={location.state}*/}
      {/*    data-contractor-id={location.id}*/}
      {/*    onClick={() => emailChangeState(true)}*/}
      {/*  >*/}
      {/*    Email*/}
      {/*  </a>*/}
      {/*  <EmailProfessionalModal*/}
      {/*    location={location}*/}
      {/*    open={emailOpen}*/}
      {/*    portal={emailModalElementFunc}*/}
      {/*    onClose={emailOnClose}*/}
      {/*  />*/}
      {/*</If>*/}
      {/*<If condition={location.source != 'ManualLocationSource' && location.website}>*/}
      {/*  |*/}
      {/*</If>*/}
      <If condition={location.website}>
        <a
          href={location.website}
          target="_blank"
          className="link-text"
          data-track="website"
          data-track-contractor={`${location.name} - ${location.city}, ${location.state}`}
          data-track-contractor-id={location.id}
          data-track-professional="insulation contractor"
          rel="noreferrer"
        >
          Website
        </a>
      </If>
    </p>
  );
});

function App({ ...props }) {
  return (
    <Provider store={store}>
      <FindAProfessional {...props} />
    </Provider>
  );
}

export default App;
