import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import _ from 'lodash';

// Components
import SelectDropdown from '../../../ComponentLibrary/input-elements/oc-select-dropdown';
import FormButton from '../../../ComponentLibrary/input-elements/oc-form-button';
import TextInput from "../../../ComponentLibrary/input-elements/oc-text-input";
import FormModal from '../../../ComponentLibrary/forms/oc-form-modal';

// Styles
const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 24px 28px;
  width: 100%;

  &:nth-of-type(odd) {
    background-color: #F8F8F8;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const RowHeader = styled.h5`
  font-size: 16px;
  font-weight: 700;
`;

const Data = styled.div`
  display: inline-block;
  flex-shrink: 0;
  padding-left: ${props => props.paddingLeft || "12px"};
  padding-right: 12px;
  position: relative;
  width: ${props => props.width || "25%"};

  @media screen and (max-width: 767px) {
    margin-bottom: 1em;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.markup-value {
    color: #6D6D6D;

    strong {
      display: inline-block;
      margin-bottom: 12px;
      width: 100%;
    }

    span {
      display: inline-block;
      padding: 14px 8px;
    }
  }

  h4 {
    margin-bottom: 0;
  }
`;

const Toggle = styled.div`
  align-items: center;
  display: inline-flex;
  position: relative;
  width: 100%;

  input[type="checkbox"] {
    display: none;

    &:checked + label {
      border-color: #D40F7D;

      &:after {
        background-color: #D40F7D;
        left: calc(100% - 2px);
        transform: translateX(-100%);
      }
    }
  }

  label {
    background-color: transparent;
    border: 1px solid #6D6D6D;
    border-radius: 20px;
    cursor: pointer;
    display: block;
    height: 25px;
    margin-right: 16px;
    position: relative;
    text-indent: -9999px;
    width: 45px;

    &:after {
      background-color: #6D6D6D;
      border-radius: 20px;
      content: '';
      height: 19px;
      left: 2px;
      position: absolute;
      top: 2px;
      transition: 0.3s;
      width: 19px;
    }

    &:active:after {
      width: 25px;
    }
  }
`;

const ToggleOption = styled.span`
  display: inline-block;
  position: relative;
  margin-right: 16px;
`;

const EditContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }

  span {
    margin: 0 4px;
    color: #D40F7D;
  }
`;

const FormSection = styled.div`
  display: inline-block;
  margin-bottom: 32px;
  position: relative;
  width: 100%;

  &.split {
    display: flex;
    flex-wrap: wrap;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
  position: relative;

  button {
    margin-right: 1em;
  }
`;

const warrantyTiers = [
  {
    tierLevel: 0,
    title: "Standard Product Limited Warranty",
    description: "All Owens Corning® Shingle products come with a standard product limited warranty providing coverage against manufacturing defects. Each warranty starts with a TRU PROtection® Period during the initial years where coverage includes labor cost to repair/replace defective materials. After the TRU PROtection® Period is over, the coverage is for materials only and adjusted based on the age of your roof.",
    deletable: false,
    display: true
  },
  {
    tierLevel: 1,
    title: "System Protection Limited Warranty",
    description: "Provides full replacement coverage for manufacturing defects on your Owens Corning® shingles and all Owens Corning® roofing components for 50 years.",
    deletable: false,
    display: true
  },
  {
    tierLevel: 2,
    title: "Preferred Protection Limited Warranty",
    description: "Provides full replacement coverage for manufacturing defects on your Owens Corning® shingles and all Owens Corning® roofing components for 50 years. This warranty includes a 10-year limited warranty on contractor workmanship.",
    deletable: false,
    display: true
  },
  {
    tierLevel: 3,
    title: "Platinum Protection Limited Warranty",
    description: "Provides full replacement coverage for manufacturing defects on your Owens Corning® shingles and all Owens Corning® roofing components for 50 years. This warranty includes a limited lifetime warranty on contractor workmanship.",
    deletable: false,
    display: true
  }
];

const WarrantyDetailSelection = ({ configuration, updateData }) => {
  const [currentSelection, setCurrentSelection] = useState(undefined);
  const [warrantyOptionsAvailable, setWarrantyOptionsAvailable] = useState("");
  const [editWarrantyOpen, setEditWarrantyOpen] = useState(false);

  // Get available warranty options
  useEffect(() => {
    const warrantyOptions = getWarrantyOptionsAvailable();
    setWarrantyOptionsAvailable(warrantyOptions);
  }, [])

  useEffect(() => {
    if (!configuration.data['warrantySelected']) {
      updateData(null, 'warrantySelected', warrantyTiers[configuration.data['tierLevel']])
      setCurrentSelection(warrantyTiers[configuration.data['tierLevel']]);
    } else {
      setCurrentSelection(configuration.data['warrantySelected'])
    }
  }, [configuration.data])

  const getWarrantyOptionsAvailable = () => {
    const tierLevel = configuration.data['tierLevel'];

    const warrantyOptions = warrantyTiers.filter((warranty) => {
      return warranty.tierLevel <= tierLevel
    })

    return warrantyOptions;
  }

  const handleEditWarranty = () => {
    setEditWarrantyOpen(true)
  }

  const handleWarrantySelectChange = (event) => {
    const selectedWarranty = warrantyTiers.find((warranty) => {
      return warranty.title === event.target.value;
    });

    setCurrentSelection(selectedWarranty);
  }

  const handleSaveWarrantySelection = () => {
    updateData(null, 'warrantySelected', currentSelection);
    setEditWarrantyOpen(false);
  }

  const closeWarrantyEditModal = () => {
    setCurrentSelection(configuration.data['warrantySelected'] || warrantyTiers[configuration.data['tierLevel']]);
    setEditWarrantyOpen(false);
  }

  const handleWarrantyDisplayChange = () => {
    const warrantySelectedData = _.cloneDeep(configuration.data['warrantySelected']);
    warrantySelectedData.display = !warrantySelectedData.display;
    updateData(null, 'warrantySelected', warrantySelectedData);
  }

  return (
    <>
      <Row key="warrantyDetail">
        <Data width="auto">
          <Toggle>
            <ToggleOption>Off</ToggleOption>
            <input
              checked={configuration.data[`warrantySelected`]?.display}
              id={`quote-detail-warranty-toggle`}
              onChange={handleWarrantyDisplayChange}
              type="checkbox"
            />
            <label htmlFor={`quote-detail-warranty-toggle`}></label>
            <ToggleOption>On</ToggleOption>
          </Toggle>
        </Data>
        <Data width="15%">
          <EditContainer onClick={handleEditWarranty}>
            <span className="fa fa-solid fa-pencil-square-o"></span>
            <span>Edit</span>
          </EditContainer>
        </Data>
        <Data width="60%">
          <RowHeader>{currentSelection?.title}</RowHeader>
          <p>
            {currentSelection?.description}
          </p>
        </Data>
      </Row>
      {editWarrantyOpen &&
        <FormModal
          name="Edit Warranty"
          onClose={closeWarrantyEditModal}
          open={true}
        >
          <h3 uppercase="true" mt="14">Warranty Selection</h3>
          <form onSubmit={handleSaveWarrantySelection}>
            <FormSection>
              <SelectDropdown
                label="Warranty"
                options={warrantyOptionsAvailable.map((warranty) => { return warranty.title })}
                defaultValue={currentSelection.title}
                onChange={handleWarrantySelectChange}
              />
            </FormSection>
            <FormSection>
              <TextInput
                label="Description"
                name="warranty-description"
                multiline={true}
                rows={4}
                value={currentSelection.description}
                disabled={true}
              />
            </FormSection>
            <ModalFooter>
              <FormButton type="submit">
                Save
              </FormButton>
              <FormButton
                onClick={closeWarrantyEditModal}
                background="white"
                color="#FFFFFF"
              >
              Cancel
              </FormButton>
            </ModalFooter>
          </form>
        </FormModal>
        }
    </>
  );
};

export default WarrantyDetailSelection;
