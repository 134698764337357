import React from "react";
import objectFitImages from 'object-fit-images';
import fallback from "../../../../../assets/images/brand_center/FallbackImage.png";


const AssetViewModes = {
  grid: {
    icon: "th-large",
    title: "Grid"
  },
  list: {
    icon: "list-ul",
    title: "List"
  }
};

export const AssetViewToggle = ({ onToggle, view }) => (
  <div className="asset-view-toggle" onClick={onToggle} data-track="view-change" data-track-view={AssetViewModes[(view == 'grid' ? 'list' : 'grid')].title} >
    <i className={`fa fa-${AssetViewModes[view].icon}`} aria-hidden="true" ></i>
    <span>{`${AssetViewModes[view].title} View`}</span>
  </div>
);

export const AssetPageControl = ({ onPrev, onNext, onSelect, limit, current, pagination, bottom }) => {
  let buttons = [];

  let total = pagination.pageCount + 1;
  let distance = { start: Math.ceil(limit / 2), stop: Math.floor(limit / 2) };

  let start = Math.max(1, current - distance.start);
  let stop = Math.min(start + Math.min(limit, total), total);

  start = Math.max(1, start - Math.max(0, limit - (stop - start)));

  for (let i = start; i <= stop; i++) buttons.push({ value: i, page: true });

  if (current - (distance.start) > 1) {
    buttons[0] = { value: '...', page: false, key: 'dot-start' };
    buttons.unshift({ value: 1, page: true });
  }

  if (current + distance.stop < total) {
    buttons[buttons.length - 1] = { value: '...', page: false, key: 'dot-end' };
    buttons.push({ value: total, page: true });
  }

  return (
    <div className="asset-page-control" >
      <div className={`asset-page-button asset-page-prev${(current == 1) ? ' disabled' : ''}`} onClick={((e) => onPrev(bottom))} key="prev" >
        <i className="fa fa-angle-double-left" aria-hidden="true"></i>
      </div>
      {
        buttons.map((button) => {
          if (button.page) {
            return (
              <div
                className={`asset-page-button asset-page-select${(current == button.value) ? ' page-selected' : ''}`}
                onClick={((e) => onSelect(e, button.value, bottom))}
                key={`page-${button.value}`}
              >
                {button.value}
              </div>
            );
          } else {
            return (
              <div
                className="asset-page-button asset-page-select disabled asset-page-spacer"
                key={`page-${button.key}`}
              >
                {button.value}
              </div>
            );
          }
        })
      }
      <div className={`asset-page-button asset-page-next${(current == pagination.pageCount + 1) ? ' disabled' : ''}`} onClick={((e) => onNext(bottom))} key="next" >
        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
      </div>
    </div>
  );
};

class AssetItem extends React.Component {
  componentDidMount() {
    objectFitImages('img.asset-thumbnail-image');
  }

  render() {
    return (
      <div className="asset-item" >
        <a href={`/corporate/brand-center/assets/${this.props.identifier}?q=${this.props.query}`} data-track="learn-more" data-track-element-location="search result" data-track-destination={this.props.title} >
          <div className="asset-thumbnail" >
            <img className="asset-thumbnail-image" src={this.props.thumbnail} alt={this.props.alt} onError={(e) => (e.currentTarget.src = fallback)}/>
          </div>
          <div className="asset-details" >
            <div className="asset-title" >{this.props.title}</div>
            <div className="asset-classification" >{this.props.classification}</div>
            <div className="asset-description" >{this.props.description}</div>
          </div>
        </a>
      </div>
    );
  }
};

export const AssetList = ({ query, assets }) => assets.map(t => <AssetItem key={t.id} query={query} {...t} />);
