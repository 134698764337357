import { useEffect, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import PropTypes from 'prop-types';

// Utilities
import withDefaultPrevented from "../../../withDefaultPrevented";

// Styled Components
const Overlay = styled.a`
  bottom: 0;
  cursor: default;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
`;

const Close = styled.a`
  color: black;
  cursor: pointer;
  font-size: 34px;
  font-weight: 700;
  line-height: 18px;
  margin: 15px;
  padding: 15px;
  text-decoration: none;
`;

const ModalContainer = styled.div`
  bottom: 0;
  height: 0;
  left: 0;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(0, 100%, 0);
  transition: opacity .4s;
  visibility: hidden;
  width: 0;
  z-index: 1000;

  &.no-target {
    background: rgba(0, 0, 0, 0.4);
    height: auto;
    opacity: 1;
    transform: translate(0, 0);
    visibility: visible;
    width: auto;
  }
`;

const ModalBody = styled.div`
  background: white;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 75%);
  margin: 2em auto;
  max-width: 640px;
  overflow-x: hidden;
  position: relative;
  width: 95%;
  z-index: 100;

  &.lg {
    max-width: 900px;
  }

  &:not(:focus-within) {
    background-color: #FFFFFE;
    transition: background-color 0.01s;
  }
`;

const ModalContent = styled.div`
  padding: 16px;
`;

function isInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
      ),
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

const FormModal = ({
  children,
  className = '',
  data = {},
  name,
  onClose,
  open = false,
  size = '',
  modalBodyPosition = false,
  ...props
}) => {
  const modal = useRef(null);
  const modalTabBoundaryStart = useRef(null);
  const modalTabBoundaryEnd = useRef(null);
  const classes = ["oc-modal", className.split(" ")].flat().filter(string => string.trim());
  const bodyClasses = ["modal-body", size].flat().filter(string => string.trim());
  if (open) classes.push("no-target");

  let focusedElementBeforeOpen = null;
  let focusableElements = null;
  let firstFocusableElement = null;
  let lastFocusableElement = null;

  const onCloseClick = withDefaultPrevented(() => {
    if (focusedElementBeforeOpen) {
      focusedElementBeforeOpen.focus();
    }
    onClose && onClose();
  });

  const modalIsInViewport = isInViewport(modal);

  const focusOnOpen = () => {
    setTimeout(() => {
      if (open && modalIsInViewport) {
        focusedElementBeforeOpen = document.activeElement;
        focusableElements = Array.prototype.slice.call(modal.current.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'));
        firstFocusableElement = focusableElements[0];
        lastFocusableElement = focusableElements[focusableElements.length - 1];

        firstFocusableElement.focus();
      }
    }, 100);
  }

  const keyUp = (event) => {
    const KEY_TAB = 9;
    const KEY_ESC = 27;

    if (!open) {
      return;
    }

    switch (event.keyCode) {
      case KEY_TAB:
        if (event.shiftKey) {
          handleBackwardTab();
        } else {
          handleForwardTab();
        }
        break;
      case KEY_ESC:
        onCloseClick(event);
        break;
      default:
        break;
    }
  }

  const handleBackwardTab = () => {
    if (document.activeElement == modalTabBoundaryStart.current) {
      lastFocusableElement && lastFocusableElement.focus();
    }
  }

  const handleForwardTab = () => {
    if (document.activeElement == modalTabBoundaryEnd.current) {
      firstFocusableElement && firstFocusableElement.focus();
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', keyUp);
    focusOnOpen();
  });

  if (!open) {
    return null;
  }

  return (
    <ModalContainer className={classes.join(" ")} role="dialog" aria-labelledby="modal-title">
      <Overlay
        aria-hidden="true"
        aria-label="Close"
        data-track-modal-name={name}
        data-track="close-modal"
        onClick={onCloseClick}
        tabIndex="-1"
      />

      <h3 id="modal-title" style={{ display: 'none' }}>{name}</h3>

      <a id="modal-tab-boundary-start" ref={modalTabBoundaryStart} tabIndex="0"></a>
      <ModalBody
        className={bodyClasses.join(" ")}
        data-structure="modal-body"
        data-am-region={data['data-am-region']}
        ref={modal}
        style={(modalBodyPosition ? { position: "absolute"} : {})}
      >
        <Close
          aria-label="Close"
          className="close"
          data-track-modal-name={name}
          data-track="close-modal"
          href="#"
          onClick={onCloseClick}
          tabIndex="0"
        >
          <span aria-hidden="true">&times;</span>
        </Close>

        <ModalContent>
          {children}
        </ModalContent>
      </ModalBody>
      <a id="modal-tab-boundary-end" ref={modalTabBoundaryEnd} tabIndex="0"></a>
    </ModalContainer>
  );
};

export default FormModal;

FormModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  data: PropTypes.object,
  name: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  modalBodyPosition: PropTypes.boolean
}
