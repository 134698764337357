import { cloneElement, useRef, useState } from 'react';
import { css } from '@emotion/react';

import useOCStickyNav from './hooks/use-oc-sticky-nav';
import Link from './oc-link';
import Text from '../OC/PageBuilder/utilities/Text';

import { connectLocation } from '../location/LocationConnectors';
import { useAccessibleInteractivity } from '../../hooks';
import wrappedInRouter from '../wrappedInRouter';
import Theme from '../../themes/index';

const Styles = css`
  & #mobile-stickynav {
    display: none;
  }

  & .oc-stickynav {
    margin-top: 0px !important;
  }

  & .cta-group {
    flex-shrink: 0;
    .oc-cta-container {
      padding-top: 0 !important;
    }
  }

  & .sticky-wrapper {
    @media (min-width: 768px) {
      height: 95px;
      overflow: hidden;
    }
  }

  & .stickynav-container {
    ul {
      @media (min-width: 768px) {
        display: flex !important;
        li {
          line-height: 0px !important;
          &.hovering * {
            color: ${Theme.colors.brand};
          }
        }
      }
    }
  }
`;

const StickyNav = connectLocation(({ t, cta, items, children }) => {
  const [isMobileExpanded, expandMobile] = useState(false);
  const mobileRef = useRef();
  useAccessibleInteractivity(mobileRef, () => expandMobile(!isMobileExpanded));

  const navRef = useRef();
  const wrapperRef = useRef();
  const containerRef = useRef();

  const { hovering, onClick } = useOCStickyNav({
    navRef,
    wrapperRef,
    containerRef,
    items,
  });

  return (
    <div css={Styles}>
      <div className="oc-stickynav" data-am-region="Sticky Nav" ref={navRef}>
        <div className="sticky-wrapper" ref={wrapperRef}>
          <div className="stickynav-container" ref={containerRef}>
            {cta ? (
              <div className="cta-group">
                {cloneElement(cta, {
                  'data-track-element-location': 'sticky nav CTA',
                })}
              </div>
            ) : null}

            {/* readOnly here doesn't do anything, it's just here to make React chill out */}
            <input
              type="checkbox"
              id="mobile-stickynav"
              checked={isMobileExpanded}
              readOnly
            />
            <label htmlFor="mobile-stickynav">
              <span
                className="mobile-stickynav-header"
                data-track="reveal"
                data-track-accordion="mobile-stickynav"
                tabIndex="0"
                ref={mobileRef}
                aria-expanded={isMobileExpanded}
                aria-controls="stickynav-slider"
                role="button"
              >
                {t('owenscorning.components.sticky_nav.page_menu')}
              </span>
            </label>
            <ul
              id="stickynav-slider"
              className="stickynav-links"
              aria-hidden="false"
            >
              {items.map(({ heading, anchor, tabName }) => {
                if (!heading) return null;
                return (
                  <li
                    id={`menu-${anchor}`}
                    className={anchor == hovering && 'hovering'}
                    key={anchor}
                  >
                    <Link
                      onClick={e => onClick(e, anchor)}
                      href={`#${anchor}`}
                      data-track="tab-click"
                      data-track-tab-name={tabName || heading}
                      className="anchor"
                      tabIndex="0"
                    >
                      <Text content={heading} />
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
});

StickyNav.WrappedComponent.displayName = 'StickyNav';

export default wrappedInRouter(StickyNav);
