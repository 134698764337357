import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";
import _, { set } from "lodash";


// components
import FormModal from '../../../ComponentLibrary/forms/oc-form-modal';
import QuoteDetailsForm from './QuoteDetailsForm';
import WarrantyDetailSelection from './WarrantyDetailSelection';
import DeleteItemForm from './DeleteItemForm';

// constants
const DEFAULT_HEADER_TEXT = `The following section allows you to help homeowners understand what information will be included in an official quote.
Select which information you want listed out to the homeowner.`

const DEFAULT_QUOTE_DETAILS = [
  {
    title: "Financing Options",
    description: "We understand that every customer has different needs and preferences when it comes to financing their purchases. That's why we offer a variety of financing options.",
    deletable: false,
    display: true,
  },
  {
    title: "Cleanup and Shingle Removal",
    description: "Part of repairing or replacing a roof involves the removal and cleanup of discarded materials. Our full quote would include costs related to cleanup/removal.",
    deletable: false,
    display: true,
  }
]

// styled components
const Section = styled.section`
  border-bottom: 2px solid #000000;
  margin-bottom: 64px;
  padding-bottom: 32px;

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const SectionTitle = styled.h3`
  margin-bottom: 16px;

  & + div {
    margin-top: 16px;
  }
`;

const SectionCopy = styled.p`
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 32px;
  }

  &.uniq {
    margin-bottom: 16px;
  }
`;

const FormSection = styled.div`
  display: inline-block;
  margin-bottom: 32px;
  position: relative;
  width: 100%;

  &.split {
    display: flex;
    flex-wrap: wrap;
  }
`;

const EditContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
  span {
    margin: 0 4px;
    color: #D40F7D;
  }
`;
const AddNewContainer = styled.div`
  cursor: pointer;
  padding-top: 32px;
  span {
    margin: 0 4px;
    font-size: 18px;
  }
`;

const Table = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 24px 28px;
  width: 100%;

  &:nth-of-type(odd) {
    background-color: #F8F8F8;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const RowHeader = styled.h5`
  font-size: 16px;
  font-weight: 700;
`;

const Data = styled.div`
  display: inline-block;
  flex-shrink: 0;
  padding-left: ${props => props.paddingLeft || "12px"};
  padding-right: 12px;
  position: relative;
  width: ${props => props.width || "25%"};

  @media screen and (max-width: 767px) {
    margin-bottom: 1em;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.markup-value {
    color: #6D6D6D;

    strong {
      display: inline-block;
      margin-bottom: 12px;
      width: 100%;
    }

    span {
      display: inline-block;
      padding: 14px 8px;
    }
  }

  h4 {
    margin-bottom: 0;
  }
`;

const DetailHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid #959595;
  display: flex;
  position: relative;
  font-family: OC Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;


  h6 {
    flex-grow: 1;
    margin-bottom: 8px;
  }
`;

const Toggle = styled.div`
  align-items: center;
  display: inline-flex;
  position: relative;
  width: 100%;

  input[type="checkbox"] {
    display: none;

    &:checked + label {
      border-color: #D40F7D;

      &:after {
        background-color: #D40F7D;
        left: calc(100% - 2px);
        transform: translateX(-100%);
      }
    }
  }

  label {
    background-color: transparent;
    border: 1px solid #6D6D6D;
    border-radius: 20px;
    cursor: pointer;
    display: block;
    height: 25px;
    margin-right: 16px;
    position: relative;
    text-indent: -9999px;
    width: 45px;

    &:after {
      background-color: #6D6D6D;
      border-radius: 20px;
      content: '';
      height: 19px;
      left: 2px;
      position: absolute;
      top: 2px;
      transition: 0.3s;
      width: 19px;
    }

    &:active:after {
      width: 25px;
    }
  }
`;

const ToggleOption = styled.span`
  display: inline-block;
  position: relative;
  margin-right: 16px;
`;

const QuoteDetails = ({ configuration, updateData }) => {
  let configurationDetails = configuration.data["quoteDetails"] || [];
  const [quoteDetails, setQuoteDetails] = useState(configurationDetails);
  const [displayFormModal, setDisplayFormModal] = useState(false);
  const [editingQuoteDetail, setEditingQuoteDetail] = useState({ isNew: true, quoteDetail: { title: "", description: "", deletable: true, display: true } });
  const [itemToDelete, setItemToDelete] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);

  const saveQuoteDetail = (event, modalQuoteDetail) => {
    event.preventDefault();

    let updatedCollection = _.cloneDeep(quoteDetails)
    if (editingQuoteDetail.isNew) {
      updatedCollection = [...updatedCollection, modalQuoteDetail]
      setQuoteDetails([...quoteDetails, modalQuoteDetail]);
    } else {
      updatedCollection[editingQuoteDetail.quoteDetailIndex] = modalQuoteDetail;
    };

    setQuoteDetails(updatedCollection);
    updateData(null, "quoteDetails", updatedCollection)
    setDisplayFormModal(false);
    setEditingQuoteDetail({ isNew: true, quoteDetail: { title: "", description: "", deletable: true, display: true } });
  }

  const editQuoteDetail = (index) => {
    setEditingQuoteDetail({
      isNew: false,
      quoteDetailIndex: index,
      quoteDetail: quoteDetails[index],
    });
    setDisplayFormModal(true);
  }

  const handleItemToDelete = (index) => {
    setItemToDelete(quoteDetails[index]);
    setSelectedIndex(index);
    setDisplayDeleteModal(true);
  }

  const deleteItem = (indexToDelete) => {
    let collection = _.cloneDeep(quoteDetails);
    let updatedCollection = _.reject(collection, (__, index) => { return index == indexToDelete });
    setQuoteDetails(updatedCollection);
    updateData(null, "quoteDetails", updatedCollection);
    setDisplayDeleteModal(false);
  };

  const closeFormModal = () => {
    setDisplayFormModal(false);
    setEditingQuoteDetail({ isNew: true, quoteDetail: { title: "", description: "", deletable: true } });
  };

  const closeDeleteModal = () => {
    setDisplayDeleteModal(false);
    setItemToDelete(null);
  }

  const updateDetailDisplay = (index) => {
    const updatedDetails = [...quoteDetails];
    updatedDetails[index].display = !updatedDetails[index].display;
    setQuoteDetails(updatedDetails);
    updateData(null, "quoteDetails", updatedDetails);
  };

  // load default details if none exist
  useEffect(() => {
    let configurationDetails = configuration.data["quoteDetails"] || [];
    if (configurationDetails.length === 0) {
      setQuoteDetails(DEFAULT_QUOTE_DETAILS);
      updateData(null, "quoteDetails", DEFAULT_QUOTE_DETAILS);
    }
  }, [configuration.data]);

  return (
    <>
      <SectionTitle>Complete Quote Details</SectionTitle>
      <SectionCopy>
        {DEFAULT_HEADER_TEXT}
      </SectionCopy>
      <FormSection>
        <DetailHeader>
          <h6>Customizations</h6>
        </DetailHeader>
        <Table>
          <WarrantyDetailSelection configuration={configuration} updateData={updateData} />
          {quoteDetails && quoteDetails.map((detail, index) => (
            <Row key={index}>
              <Data width="auto">
                <Toggle>
                  <ToggleOption>Off</ToggleOption>
                  <input
                    checked={quoteDetails[index].display}
                    id={`quote-detail-${index}-toggle`}
                    onChange={() => updateDetailDisplay(index)}
                    type="checkbox"
                  />
                  <label htmlFor={`quote-detail-${index}-toggle`}></label>
                  <ToggleOption>On</ToggleOption>
                </Toggle>
              </Data>
              <Data width="15%">
                <EditContainer onClick={() => editQuoteDetail(index)}>
                  <span className="fa fa-solid fa-pencil-square-o"></span>
                  <span>Edit</span>
                </EditContainer>
              </Data>
              <Data width={"60%"}>
                <RowHeader>{detail.title}</RowHeader>
                <p>
                  {detail.description}
                </p>
              </Data>
              {detail.deletable &&
                <Data width="10%">
                  <EditContainer onClick={() => handleItemToDelete(index)}>
                    <span className="fa fa-solid fa-trash"></span>
                    <span>Delete</span>
                  </EditContainer>
                </Data>
              }
            </Row>
          ))}
          <AddNewContainer onClick={() => setDisplayFormModal(true)}>
            <span>Add New Section</span>
            <span className="fa fa-solid fa-chevron-right"></span>
          </AddNewContainer>
        </Table>
      </FormSection>
      {displayFormModal &&
        <FormModal
          name="Quote Details Form Modal"
          onClose={closeFormModal}
          open={true}
        >
          <QuoteDetailsForm
            closeModal={closeFormModal}
            isNew={editingQuoteDetail.isNew}
            quoteDetail={editingQuoteDetail.quoteDetail}
            saveQuoteDetail={saveQuoteDetail}
          />
        </FormModal>
      }
      {displayDeleteModal && selectedIndex &&
        <FormModal
          name="Delete Item Modal"
          onClose={closeDeleteModal}
          open={true}
        >
          <DeleteItemForm
            item={itemToDelete}
            closeModal={closeDeleteModal}
            deleteItem={deleteItem}
            index={selectedIndex}
          />
        </FormModal>
      }
    </>
  );
};
export default QuoteDetails;
