import styled from '@emotion/styled';
import Picture from "../oc-picture";

const HeroContainer = styled.div`
    background-size: cover;

   @media(min-width: 768px) {
      background-image: url(${props => Picture.Transform(props.imgTablet)});
      background-repeat: no-repeat;
    }
    @media (min-width: 1200px) {
      background-image: url(${props => Picture.Transform(props.imgDesktop)});
      height: 245px;
      background-size: 1340px;
    }
    @media (min-resolution: 2dppx) and (min-width: 1200px) {
      /* background-image: url(${props => Picture.Transform(props.hero_2x)}); */
    }
`;

const HeroGrid = styled.div`
    @media screen and (min-width: 768px) {
      position: relative;
      width: 100%;
      height: 200px;
    }

    @media screen and (min-width: 1200px) {
      height: 245px;
    }
`;

const HeroPageHeading = styled.div`
    padding: 0 15px;

    @media(min-width: 768px) {
      background: white;
      position: absolute;
      top: 0px;
      left: 0px;
      padding: 0 15px 15px 15px;
      max-width: 50%;
    }

    @media screen and (min-width: 1200px) {
      padding: 1px 30px 30px 86px;
    }
`;

const PostHead = styled.span`
  font-family: "OCRoboto", sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 16px;
  margin: 16px 0 0;
  display: block;
  color: #6d6d6d;
  font-size: 16px;
  line-height: 16px;

  @media (min-width: 1200px) {
    margin: 16px 0 0;
    font-size: 18px;
    line-height: 21px;
  }
`

const BottomRightFill = styled.div`
  @media (min-width: 768px) {
    background: white;
    position: absolute;
    bottom: 0px;
    right: 0px;

    width: 155px;
    height: 40px;
  }

  @media (min-width: 1200px) {
    width: 155px;
    height: 70px;
  }
`

const defaults = {
  heroDesktop: "http://placehold.it/2680x490",
  heroTablet: "http://placehold.it/1600x400"
}

// TODO support all the "cutaway styles"
const HeroTier3 = ({imgDesktop, imgTablet, prehead, h1, posthead, children, ...props}) => {
  imgDesktop = imgDesktop || defaults.heroDesktop;
  imgTablet = imgTablet || defaults.heroTablet;

  return (
    <HeroContainer imgDesktop={imgDesktop} imgTablet={imgTablet} {...props}>
      <HeroGrid>
        <HeroPageHeading>
          {prehead ? <span className="prehead tier3">{prehead}</span> : null}
          <h1 className="tier3">{h1}</h1>
          {posthead ? <PostHead>{posthead}</PostHead> : null}
        </HeroPageHeading>
        <BottomRightFill />
      </HeroGrid>
    </HeroContainer>
  );
}

export default HeroTier3;
