import Theme from '../../../themes/index';
import PropTypes from 'prop-types';

// Components
import BaseInput from './oc-input-base';

const PhoneInput = ({
  className = "",
  defaultValue,
  disabled = false,
  error,
  helperText,
  infoFunction,
  label,
  name,
  themeColor = Theme.colors.brand,
  onChange,
  onBlur,
  placeholder,
  required = false,
  touched = false,
  valid = true,
  value
}) => {
  // const [phoneError, setPhoneError] = useState('')
  // const validatePhone = (value) => {};

  return (
    <BaseInput
      className={className}
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      helperText={helperText}
      id={name}
      infoFunction={infoFunction}
      inputType="tel"
      label={label}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      name={name}
      themeColor={themeColor}
      onBlur={onBlur}
      onChange={onChange}
      pattern="([0-9]{3}) [0-9]{3}-[0-9]{4}"
      placeholder={placeholder}
      required={required}
      touched={touched}
      valid={valid}
      value={value}
    />
  );
}

export default PhoneInput;

PhoneInput.propTypes = {
  /* HTML: Sets the disabled attribute on the input */
  disabled: PropTypes.bool,
  /* HTML: Controls the display of the error text structure under the input */
  error: PropTypes.string,
  /* HTML: Additional text shown after the label, ex: "Required" */
  helperText: PropTypes.string,
  /* HTML: Label text displayed above the input */
  label: PropTypes.string,
  /* HTML: Sets the input name attribute */
  name: PropTypes.string,
  /* CSS: Hex value for color theme */
  themeColor: PropTypes.string,
  /* HTML: Sets the input placeholder text */
  placeholder: PropTypes.string,
  /* HTML: Determines whether or not the input is required */
  required: PropTypes.bool,
  /* JSX: Controls the error state of the input and display of error text and styles */
  valid: PropTypes.bool,
  /* HTML: Sets the default value of the input */
  value: PropTypes.string
}
